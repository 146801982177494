import React, { useEffect, useState } from "react";
import styles from "./WorkRegular.module.css";
import Loading from "components/loading/LoadingBackground";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Avatar, Typography, Divider } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import { useForm, Controller } from "react-hook-form";
import {
  getProjectById,
  editProject,
  calculateCreditProject,
} from "utils/api/project/project";
import { AccountCircle, StarRateRounded, Save } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import helper from "utils/helper";
import FieldSelect from "components/field/FieldSelect";
import {
  ActiveStatus,
  FreightType,
  PaymentType,
  ProjectFrequencyType,
  ReminderDate,
  WorkType,
} from "utils/enum";
import FieldInput from "components/field/FieldInput";
import FieldUpload from "components/field/FieldUpload";
import { getAllProductType, getAllService } from "utils/api/masterData/service";
import AlertModal from "components/modal/AlertModal";
import {
  editWorkRegular,
  getWorkRegularById,
} from "utils/api/workRegular/workRegular";
import { findCompanyName as findCompanyNameAPI } from "utils/api/profile/profile";
import { getTruckType as getTruckTypeMS } from "utils/api/masterData/truckType";
import ButtonCustom from "components/ButtonCustom";
import SelectTruck from "components/work/selectTruck";
import SelectService from "components/work/selectService";
import { getAllProvince, getAllZone } from "utils/api/masterData/gis";
import FieldTextArea from "components/field/FieldTextArea";
import FieldSelectArea from "components/field/FieldSelectArea";
const WorkRegularDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [creditInfo, setCreditInfo] = useState(null);
  const [serviceMaster, setServiceMaster] = useState([]);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [initialFormValues, setInitialFormValues] = useState({});
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [userCode, setUserCode] = useState(null);
  const [truckType, setTruckType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [zoneMS, setZoneMS] = useState([]);
  const [provinceMS, setProvinceMS] = useState([]);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: "",
    desc: "",
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: {
      service: {
        has_document_return: false,
        document_return_code: "",
        document_return_address: "",
        document_return_district: "",
        document_return_subdistrict: "",
        document_return_province: "",
        document_return_postal_code: "",
        document_return_contact_name: "",
        document_return_contact_phone: "",
        has_labor: false,
        labor_load_amount: 0,
        labor_unload_amount: 0,
      },
    },
  });

  const shipper_payment_type = watch("shipper_payment_type");
  const MenuProject = [
    {
      label: "รายละเอียดงานประจำ",
      path: "",
      active: true,
    },
  ];

  const breadcrumbs = [
    {
      label: "ประกาศงาน",
      path: "",
    },
    {
      label: `จัดการงานประจำ`,
      path: "",
    },
    {
      label: "รายละเอียดงานประจำ",
      path: "",
    },
  ];

  useEffect(() => {
    if (id) {
      getDetailWorkRegular(id);
    }
  }, [id]);

  useEffect(() => {
    console.log("truckType", truckType);
    let truck_default = truckType?.filter(
      (e) => e.id === dataDetail?.truck_type_id
    );
    if (truck_default) {
      setInitialFormValues({
        ...initialFormValues,
        truck: truck_default[0],
      });
      setValue("truck", truck_default[0]);
    }
  }, [truckType, dataDetail]);

  useEffect(() => {
    console.log("dataDetail", dataDetail);
    if (dataDetail) {
      const newInitialFormValues = {};
      newInitialFormValues.active = String(dataDetail?.active) ?? "";
      newInitialFormValues.profile_id = {
        id: dataDetail?.profile_info?.id ?? "",
        company_name: dataDetail?.profile_info?.company_name ?? "",
      };
      setUserCode(dataDetail?.profile_info?.user_info?.user_code);
      newInitialFormValues.work_regular_name =
        dataDetail?.work_regular_name ?? "";
      newInitialFormValues.product_type_id = dataDetail?.product_type_id ?? "";
      newInitialFormValues.product_name = dataDetail?.product_name ?? "";

      let tmp = { ...watchService };
      tmp.has_document_return = dataDetail.has_document_return ?? false;
      tmp.has_labor = dataDetail.has_labor ?? false;
      tmp.labor_load_amount = dataDetail.labor_load_amount ?? 0;
      tmp.labor_unload_amount = dataDetail.labor_unload_amount ?? 0;
      newInitialFormValues.service = tmp;

      newInitialFormValues.more_details = dataDetail?.more_details ?? "";
      newInitialFormValues.zone_id = dataDetail?.zone_id ?? [];
      const zone = dataDetail?.workRegularZone
        ?.map((val) => val.zone)
        .filter((val) => val !== null);
      console.log("zonezone", zone);
      newInitialFormValues.zone_id = zone ?? [];

      const origin = dataDetail?.workRegularOrigin
        ?.map((val) => val.province)
        .filter((val) => val !== null);
      newInitialFormValues.origin_province_id = origin ?? [];

      const destination = dataDetail?.workRegularDestination
        ?.map((val) => val.province)
        .filter((val) => val !== null);
      newInitialFormValues.destination_province_id = destination ?? [];
      //   let truck_default = truckType?.filter(
      //     (e) => e.id === dataDetail?.truck_type_id
      //   );
      //   if (truck_default) {
      //     newInitialFormValues.truck = truck_default;
      //   }
      setInitialFormValues(newInitialFormValues);
      Object.keys(newInitialFormValues).forEach((fieldName) => {
        setValue(fieldName, newInitialFormValues[fieldName]);
      });
    }
  }, [dataDetail, setValue]);

  useEffect(() => {
    getProductType();
    getTruckType();
    getZone();
    getProvince();
  }, []);

  //   const details = watch("more_details");
  const watchTruck = watch("truck");
  const watchService = watch("service");

  const getProductType = async () => {
    await getAllProductType()
      .then((response) => {
        let results = response?.data.data.results;
        setProductType(
          results?.map((res) => ({
            value: res?.id,
            name: res?.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTruckType = async () => {
    let truck = await getTruckTypeMS({
      sort_field: "order_number",
      sort_order: 1,
      active: true,
    });
    console.log("truck", truck);
    setTruckType(truck.data.data.results);
  };
  const getZone = async () => {
    const params = {
      sort_field: "created_at",
      sort_order: 1,
    };
    await getAllZone(params)
      .then((response) => {
        let results = response.data.data.results;
        setZoneMS(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProvince = async () => {
    const params = {
      sort_field: "created_at",
      sort_order: 1,
    };
    await getAllProvince(params)
      .then((response) => {
        let results = response.data.data.results;
        setProvinceMS(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const SearchCompany = async (user_code) => {
    setLoading(true);
    await findCompanyNameAPI(user_code)
      .then((response) => {
        console.log("usercode", response.data.data);
        if (response.data.code === 1000) {
          setLoading(false);
          clearErrors("profile_id");
          setValue("profile_id", response.data.data);
        }
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data) {
          setValue("profile_id", "");
          setError("profile_id", {
            type: 400,
            message: error.response.data.description,
          });
        } else {
          clearErrors("profile_id");
        }
      });
  };
  const getDetailWorkRegular = async (id) => {
    setLoading(true);
    await getWorkRegularById(id)
      .then((response) => {
        console.log("response", response);
        if (response.data.code === 1000) {
          const rest = response.data.data;
          setDataDetail(rest);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    console.log("onSubmit", data);
    const changedData = {};

    Object.keys(data).forEach((fieldName) => {
      if (
        fieldName !== "profile_id" &&
        fieldName !== "service" &&
        fieldName !== "truck"
      ) {
        if (
          Array.isArray(data[fieldName]) &&
          JSON.stringify(data[fieldName]) !==
            JSON.stringify(initialFormValues[fieldName])
        ) {
          changedData[fieldName] = data[fieldName];
        } else if (
          !Array.isArray(data[fieldName]) &&
          data[fieldName] !== initialFormValues[fieldName]
        ) {
          changedData[fieldName] = data[fieldName];
          if (data[fieldName] === "") {
            changedData[fieldName] = null;
          } else {
            changedData[fieldName] = data[fieldName];
          }
        }
      }
    });
    if (
      data.profile_id &&
      JSON.stringify(data.profile_id) !==
        JSON.stringify(initialFormValues.profile_id)
    ) {
      changedData.profile_id = data.profile_id;
    }
    if (
      data.service &&
      JSON.stringify(data.service) !== JSON.stringify(initialFormValues.service)
    ) {
      changedData.service = data.service;
    }
    if (
      data.truck &&
      JSON.stringify(data.truck) !== JSON.stringify(initialFormValues.truck)
    ) {
      changedData.truck = data.truck;
    }
    console.log("changedData", changedData);
    const params = new URLSearchParams();
    Object.keys(changedData).forEach((key) => {
      if (key === "truck" && changedData["truck"] !== undefined) {
        params.append("truck_type_id", changedData[key]?.id);
      } else if (key === "service" && changedData["service"] !== undefined) {
        params.append(
          "has_document_return",
          changedData[key]?.has_document_return
        );
        params.append("has_labor", changedData[key]?.has_labor);
        params.append("labor_load_amount", changedData[key]?.labor_load_amount);
        params.append(
          "labor_unload_amount",
          changedData[key]?.labor_unload_amount
        );
      } else if (
        key === "profile_id" &&
        changedData["profile_id"] !== undefined
      ) {
        params.append("profile_id", changedData[key]?.id);
      } else if (changedData[key] === undefined) {
        params.delete(key);
      } else {
        params.append(key, data[key]);
      }
    });

    params.delete("truck");
    params.delete("service");

    for (const [key, value] of params.entries()) {
      console.log(`${key}: ${value}`);
    }

    await editWorkRegular(id, params)
      .then((response) => {
        console.log("response", response);
        if (response.data.code === 1000) {
          setLoading(false);
          setAlertModal({
            open: true,
            title: "บันทึก",
            desc: "บันทึกข้อมูลสำเร็จ",
          });
        }
      })
      .catch(async (error) => {
        console.log("error", error);
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  const renderCard1 = () => {
    return (
      <div className={styles.cardDetail}>
        <Typography fontSize={"20px"} fontWeight={500} sx={{ p: "13px" }}>
          {dataDetail?.work_regular_code || ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่สร้างรายการ{" "}
          {dataDetail?.created_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.created_at)
            : ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่แก้ไขข้อมูลล่าสุด{" "}
          {dataDetail?.updated_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.updated_at)
            : ""}
        </Typography>
        <Controller
          name="active"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"สถานะใช้งาน*"}
                nameDescription="(ค่าเริ่มต้น : ใช้งาน)"
                options={ActiveStatus}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <div style={{ width: "100%" }}>
          <FieldInput
            label="รหัสผู้ใช้งานหลัก*"
            subLabel={`(รหัสผู้ใช้งานหลักเท่านั้น)`}
            style={{ width: "-webkit-fill-available" }}
            value={userCode ?? ""}
            onChange={(e) => setUserCode(e.target.value)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <ButtonCustom
            name="ค้นหา"
            onClick={() => SearchCompany(userCode)}
            fullWidth={true}
          />
        </div>
        <Controller
          name="profile_id"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <div style={{ width: "100%", paddingTop: "20px" }}>
              <FieldInput
                label="ชื่อโปรไฟล์"
                style={{ width: "-webkit-fill-available" }}
                onChange={onChange}
                readOnly={true}
                value={value?.company_name ? value?.company_name : ""}
                error={!!errors.profile_id}
                helperText={errors.profile_id ? "ไม่พบโปรไฟล์" : ""}
              />
            </div>
          )}
        />
        <Controller
          name="work_regular_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label={"ชื่อประกาศ"}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                error={!!errors.work_regular_name}
                helperText={
                  errors.work_regular_name
                    ? errors.work_regular_name.message
                    : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="product_type_id"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"ประเภทสินค้า"}
                options={productType}
                value={value}
                onChange={onChange}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.role}
                errorText={errors.role ? "กรุณาเลือกประเภท" : ""}
              />
            </div>
          )}
        />
        <Controller
          name="product_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label={"ชนิดสินค้า"}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                error={!!errors.product_name}
                helperText={
                  errors.product_name ? errors.product_name.message : ""
                }
              />
            </div>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div style={{ width: "100%" }}>
              <SelectTruck
                value={value}
                onChange={(val) => {
                  setValue("truck", val);
                  console.log("watchService before", watchService);
                  let tmp = { ...watchService };
                  tmp.has_labor = false;
                  tmp.labor_load_amount = 0;
                  tmp.labor_unload_amount = 0;
                  setValue("service", tmp);
                  console.log("watchService after", watchService);
                }}
                truckType={truckType}
              />
            </div>
          )}
          name="truck"
          defaultValue=""
        />
        <div style={{ width: "100%" }}>
          <Divider orientation="horizontal" />
        </div>

        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div style={{ width: "100%" }}>
              <SelectService
                value={value}
                onChange={(val) => {
                  setValue("service", val);
                }}
                is_public={true}
                close_price={true}
                truck={watchTruck}
                close_driver_assist
              />
            </div>
          )}
          name="service"
          defaultValue=""
        />
      </div>
    );
  };
  const renderCard2 = () => {
    return (
      <div className={styles.cardDetail}>
        <div style={{ width: "100%" }}>
          <Controller
            name="zone_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div style={{ paddingTop: "20px" }}>
                <FieldSelect
                  name={"ภูมิภาคที่ต้องการให้งานแสดง"}
                  options={zoneMS}
                  defaultValue={[]}
                  value={value}
                  onChange={onChange}
                  multiSelect={true}
                  style={{ width: "-webkit-fill-available" }}
                  error={!!errors.role}
                  errorText={errors.role ? "กรุณาเลือกประเภทรถ" : ""}
                />
              </div>
            )}
          />
          <Controller
            name="origin_province_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <FieldSelectArea
                  levelArea={["provinces"]}
                  name="ต้นทาง"
                  onChange={onChange}
                  value={value}
                  showChild={true}
                />
                {/* <FieldSelect
                  name={"ต้นทาง"}
                  options={provinceMS}
                  value={value}
                  onChange={onChange}
                  defaultValue={[]}
                  multiSelect={true}
                  style={{ width: "-webkit-fill-available" }}
                  error={!!errors.role}
                  errorText={errors.role ? "กรุณาเลือกประเภทรถ" : ""}
                /> */}
              </div>
            )}
          />
          <Controller
            name="destination_province_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div>
                <FieldSelectArea
                  levelArea={["provinces"]}
                  name="ปลายทาง"
                  onChange={onChange}
                  value={value}
                  showChild={true}
                />
                {/* <FieldSelect
                  name={"ปลายทาง"}
                  options={provinceMS}
                  value={value}
                  onChange={onChange}
                  defaultValue={[]}
                  multiSelect={true}
                  style={{ width: "-webkit-fill-available" }}
                  error={!!errors.role}
                  errorText={errors.role ? "กรุณาเลือกประเภทรถ" : ""}
                /> */}
              </div>
            )}
          />
          <Controller
            name="more_details"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FieldTextArea
                placeholder="รายละเอียดเพิ่มเติม"
                onChange={onChange}
                value={value}
                minRows={20}
                maxRows={20}
              />
            )}
          />
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      {loading && <Loading />}
      {alertModal.open === true && (
        <AlertModal
          open={alertModal.open}
          title={alertModal.title}
          description={alertModal.desc}
          onClose={() => {
            setAlertModal({
              open: false,
              title: "",
              desc: "",
            });
          }}
        />
      )}
      {errorAlert.open === true && (
        <AlertModal
          open={errorAlert.open}
          title="เกิดข้อผิดพลาด"
          description={errorAlert.desc}
          onClose={() => {
            setErrorAlert({
              open: false,
              desc: "",
            });
            navigate(-1);
          }}
        />
      )}
      <Container>
        <SubMenu
          menus={MenuProject}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        />
      </Container>
      <form
        className={styles.containerDetailWorkRegular}
        onSubmit={handleSubmit(onSubmit)}
      >
        {renderCard1()}
        {renderCard2()}
        <Avatar
          alt="Avatar Save"
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
            cursor: "pointer",
            zIndex: 200,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Save sx={{ color: "white", fontSize: "70px" }} />
        </Avatar>
      </form>
    </React.Fragment>
  );
};

export default WorkRegularDetail;
