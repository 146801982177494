import React, { useState, useEffect } from "react";
import Loading from "components/loading/Loading";
import styles from "./WorkRegular.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Typography, Divider } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import FieldInput from "components/field/FieldInput";
import ButtonCustom from "components/ButtonCustom";
import FieldSelect from "components/field/FieldSelect";
import { findCompanyName as findCompanyNameAPI } from "utils/api/profile/profile";
import FieldTextArea from "components/field/FieldTextArea";
import { getAllProductType } from "utils/api/masterData/service";
import { getTruckType as getTruckTypeMS } from "utils/api/masterData/truckType";
import { styled } from "@mui/system";
import { getAllProvince, getAllZone } from "utils/api/masterData/gis";
import SelectService from "components/work/selectService";
import SelectTruck from "components/work/selectTruck";
import { createWorkRegular } from "utils/api/workRegular/workRegular";
import { useCookies } from "react-cookie";
import FieldSelectArea from "components/field/FieldSelectArea";
const ModalCreateWorkRegular = (props) => {
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [userCode, setUserCode] = useState(null);
  const [truckType, setTruckType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [zoneMS, setZoneMS] = useState([]);
  const [provinceMS, setProvinceMS] = useState([]);
  const [step, setStep] = useState(1);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: {
      service: {
        has_document_return: false,
        document_return_code: "",
        document_return_address: "",
        document_return_district: "",
        document_return_subdistrict: "",
        document_return_province: "",
        document_return_postal_code: "",
        document_return_contact_name: "",
        document_return_contact_phone: "",
        has_labor: false,
        labor_load_amount: 0,
        labor_unload_amount: 0,
      },
    },
  });

  useEffect(() => {
    getProductType();
    getTruckType();
    getZone();
    getProvince();
  }, []);

  const details = watch("more_details");
  const watchTruck = watch("truck");
  const watchService = watch("service");

  console.log("details", details);
  const SearchCompany = async (user_code) => {
    setLoading(true);
    await findCompanyNameAPI(user_code)
      .then((response) => {
        console.log("usercode", response.data.data);
        if (response.data.code === 1000) {
          setLoading(false);
          clearErrors("profile_id");
          setValue("profile_id", response.data.data);
        }
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data) {
          setValue("profile_id", "");
          setError("profile_id", {
            type: 400,
            message: error.response.data.description,
          });
        } else {
          clearErrors("profile_id");
        }
      });
  };

  const getProductType = async () => {
    await getAllProductType()
      .then((response) => {
        let results = response?.data.data.results;
        setProductType(
          results?.map((res) => ({
            value: res?.id,
            name: res?.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTruckType = async () => {
    let truck = await getTruckTypeMS({
      sort_field: "order_number",
      sort_order: 1,
      active: true,
    });
    console.log("truck", truck);
    let truck_default = truck.data.data.results.filter(
      (e) => e.order_number === 1
    );
    console.log("truck_default", truck_default[0]);
    if (truck_default.length > 0) {
      setValue("truck", truck_default[0]);
    }
    setTruckType(truck.data.data.results);
  };

  const getZone = async () => {
    const params = {
      sort_field: "created_at",
      sort_order: 1,
    };
    await getAllZone(params)
      .then((response) => {
        let results = response.data.data.results;
        setZoneMS(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProvince = async () => {
    const params = {
      sort_field: "created_at",
      sort_order: 1,
    };
    await getAllProvince(params)
      .then((response) => {
        let results = response.data.data.results;
        setProvinceMS(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePrevious = () => {
    if (step == 1) {
      props?.handleClose();
    } else if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = async (data) => {
    if (step < 4) {
      setStep(step + 1);
    }
  };

  const renderCard1 = () => {
    return (
      <div>
        <div className={styles.titleCard}>
          <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
            สร้างงานประจำ
          </Typography>
        </div>
        <div style={{ paddingTop: "20px", width: "100%" }}>
          <FieldInput
            label="รหัสผู้ใช้งานหลัก*"
            subLabel={`(รหัสผู้ใช้งานหลักเท่านั้น)`}
            style={{ width: "-webkit-fill-available" }}
            value={userCode}
            onChange={(e) => setUserCode(e.target.value)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <ButtonCustom
            name="ค้นหา"
            onClick={() => SearchCompany(userCode)}
            fullWidth={true}
          />
        </div>
        <Controller
          name="profile_id"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <div style={{ width: "100%", paddingTop: "20px" }}>
              <FieldInput
                label="ชื่อโปรไฟล์"
                style={{ width: "-webkit-fill-available" }}
                onChange={onChange}
                readOnly={true}
                value={value?.company_name ? value?.company_name : ""}
                error={!!errors.profile_id}
                helperText={errors.profile_id ? "ไม่พบโปรไฟล์" : ""}
              />
            </div>
          )}
        />
        <Controller
          name="work_regular_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label={"ชื่อประกาศ"}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                error={!!errors.work_regular_name}
                helperText={
                  errors.work_regular_name
                    ? errors.work_regular_name.message
                    : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="product_type_id"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelect
                name={"ประเภทสินค้า"}
                options={productType}
                value={value}
                onChange={onChange}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.role}
                errorText={errors.role ? "กรุณาเลือกประเภท" : ""}
              />
            </div>
          )}
        />
        <Controller
          name="product_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldInput
                label={"ชนิดสินค้า"}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                error={!!errors.product_name}
                helperText={
                  errors.product_name ? errors.product_name.message : ""
                }
              />
            </div>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <SelectTruck
              value={value}
              onChange={(val) => {
                setValue("truck", val);
                console.log("watchService before", watchService);
                let tmp = { ...watchService };
                tmp.has_labor = false;
                tmp.labor_load_amount = 0;
                tmp.labor_unload_amount = 0;
                setValue("service", tmp);
                console.log("watchService after", watchService);
              }}
              truckType={truckType}
            />
          )}
          name="truck"
          defaultValue=""
        />
        <Divider orientation="horizontal" />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <SelectService
              value={value}
              onChange={(val) => {
                setValue("service", val);
              }}
              is_public={true}
              close_price={true}
              truck={watchTruck}
              close_driver_assist={true}
            />
          )}
          name="service"
          defaultValue=""
        />
      </div>
    );
  };

  const renderCard2 = () => {
    return (
      <div>
        <Controller
          name="zone_id"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: "20px" }}>
              <FieldSelect
                name={"ภูมิภาคที่ต้องการให้งานแสดง"}
                options={zoneMS}
                defaultValue={[]}
                value={value}
                onChange={onChange}
                multiSelect={true}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.role}
                errorText={errors.role ? "กรุณาเลือกประเภทรถ" : ""}
              />
            </div>
          )}
        />
        <Controller
          name="origin_province_id"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelectArea
                levelArea={["provinces"]}
                name="ต้นทาง"
                onChange={onChange}
                value={value}
                showChild={true}
              />
              {/* <FieldSelect
                name={"ต้นทาง"}
                options={provinceMS}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.role}
                errorText={errors.role ? "กรุณาเลือกประเภทรถ" : ""}
              /> */}
            </div>
          )}
        />
        <Controller
          name="destination_province_id"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <FieldSelectArea
                levelArea={["provinces"]}
                name="ปลายทาง"
                onChange={onChange}
                value={value}
                showChild={true}
              />
              {/* <FieldSelect
                name={"ปลายทาง"}
                options={provinceMS}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                style={{ width: "-webkit-fill-available" }}
                error={!!errors.role}
                errorText={errors.role ? "กรุณาเลือกประเภทรถ" : ""}
              /> */}
            </div>
          )}
        />
        <Controller
          name="more_details"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FieldTextArea
              placeholder="รายละเอียดเพิ่มเติม"
              onChange={onChange}
              value={value}
              minRows={10}
              maxRows={10}
            />
          )}
        />
      </div>
    );
  };

  const onSubmit = async (data) => {
    console.log("datadatadatadata", data);
    const params = new URLSearchParams();
    Object.keys(data).forEach((key) => {
      if (key === "truck" && data["truck"] !== undefined) {
        params.append("truck_type_id", data[key]?.id);
      } else if (key === "service" && data["service"] !== undefined) {
        params.append("has_document_return", data[key]?.has_document_return);
        params.append("has_labor", data[key]?.has_labor);
        params.append("labor_load_amount", data[key]?.labor_load_amount);
        params.append("labor_unload_amount", data[key]?.labor_unload_amount);
      } else if (key === "profile_id" && data["profile_id"] !== undefined) {
        params.append("profile_id", data[key]?.id);
      } else if (data[key] === undefined) {
        params.delete(key);
      } else {
        params.append(key, data[key]);
      }
    });

    params.delete("truck");
    params.delete("service");

    for (const [key, value] of params.entries()) {
      console.log(`${key}: ${value}`);
    }

    await createWorkRegular(params)
      .then((response) => {
        console.log("response", response);
        if (response.data.code === 1000) {
          props.handleClose();
          setLoading(false);
          props.alertSuccess();
        }
      })
      .catch(async (error) => {
        console.log("error", error);
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            props.alertError({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };
  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? props?.handleClose : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={props?.handleClose}
            />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form
              id="form-content-work-regular"
              onSubmit={handleSubmit(onSubmit)}
            >
              {step === 1 && renderCard1()}
              {step === 2 && renderCard2()}
            </form>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={handlePrevious}
                  type={"cancel"}
                />
              </div>
              {step < 2 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name="ถัดไป"
                    onClick={handleSubmit(handleNext)}
                    fullWidth={true}
                  />
                </div>
              )}
              {step === 2 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name={"บันทึก"}
                    // type="submit"
                    onClick={handleSubmit(onSubmit)}
                    fullWidth={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCreateWorkRegular;
