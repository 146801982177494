import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as R from "ramda";
import { useCookies } from "react-cookie";
import FormModal from "components/modal/FormModal";
import CustomModal from "components/modal/CustomModal";
import API from "utils/api/auth/auth";
import styles from "./Home.module.css";
import helper from "utils/helper";
import { Box, Tab, Tabs, Grid, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HomeImage from "assets/image/truck_home.svg";

const api = API.create();

const Home = () => {
  const navigate = useNavigate();
  const { windowWidth: width, windowHeight: height } = helper.useWindowSize();
  const [listMenu, setListMenu] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
  });
  const [openModal, setOpenModal] = useState({
    type: "",
    open: false,
    title: "",
    description: "",
  });
  const [, , removeAccessToken] = useCookies(["access_token"]);

  const menu_transport = [
    {
      image: require("../../assets/image/home/User.png"),
      label: "ผู้ใช้งาน",
      link: "/user",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/Truck.png"),
      label: "รถบรรทุก",
      link: "/truck",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/Work.png"),
      label: "งานขนส่ง",
      link: "/work",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/Finance.png"),
      label: "การเงินขนส่ง",
      link: "/finance/shipper/unpaid",
      color: "#8c8dff",
    },
  ];
  const menu_trade = [
    {
      image: require("../../assets/image/home/customer.png"),
      label: "ผู้ซื้อ",
      link: "/trade/customer",
      color: "#ffc675",
    },
    {
      image: require("../../assets/image/home/jobber.png"),
      label: "ผู้ขาย",
      link: "/trade/jobber",
      color: "#ffc675",
    },
    {
      image: require("../../assets/image/home/buy.png"),
      label: "ซื้อขาย",
      link: "/trade",
      color: "#ffc675",
    },
    {
      image: require("../../assets/image/home/trade_finance.png"),
      label: "การเงินซื้อขาย",
      link: "/trade-finance",
      color: "#ffc675",
    },
  ];

  const menu_truck_rental = [
    {
      image: require("../../assets/image/home/customer.png"),
      label: "ผู้เช่า",
      link: "truck-rental/tenant",
      color: "#FF69B4",
    },
    {
      image: require("../../assets/image/home/jobber.png"),
      label: "ผู้ให้เช่า",
      link: "truck-rental/lessor",
      color: "#FF69B4",
    },
    {
      image: require("../../assets/image/home/buy.png"),
      label: "เช่ารถ",
      link: "/truck-rental/rental-order",
      color: "#FF69B4",
    },
    {
      image: require("../../assets/image/home/trade_finance.png"),
      label: "การเงินเช่ารถ",
      link: "/truck-rental/finance/tenant/unpaid",
      color: "#FF69B4",
    },
  ];

  const menu_advance = [
    {
      image: require("../../assets/image/home/Graph.png"),
      label: "แผนภูมิ",
      link: "/dashboard/work",
      color: "#27ce88",
    },
    {
      image: require("../../assets/image/home/report.png"),
      label: "รายงาน",
      color: "#27ce88",
      link: "/export-data",
    },
    {
      image: require("../../assets/image/home/Admin.png"),
      label: "ผู้ดูแลระบบ",
      link: "/admin",
      color: "#27ce88",
    },
    {
      image: require("../../assets/image/home/MasterData.png"),
      label: "ฐานข้อมูล",
      link: "/master-data/term-and-condition",
      color: "#27ce88",
    },
  ];

  const renderModal = () => {
    if (openModal.open === true) {
      return (
        <CustomModal
          open={openModal.open}
          title={openModal.title}
          onCancel={() => {
            setOpenModal({
              type: "",
              open: false,
              title: "",
              description: "",
            });
          }}
          onConfirm={async () => {
            if (openModal.type === "logOut") {
              await api
                .logout()
                .then((res) => {
                  removeAccessToken("access_token");
                  navigate("/login");
                })
                .catch((e) => console.log(e));
            }

            setOpenModal({
              type: "",
              open: false,
              title: "",
              description: "",
            });
          }}
        >
          <div>{openModal.description}</div>
        </CustomModal>
      );
    }
  };
  const renderTransport = () => {
    return (
      <Box sx={{ width: "100%", typography: "body1", paddingBottom: "15px" }}>
        <div
          style={{
            backgroundColor: "#8c8dff",
            padding: "8px",
            color: "white",
            fontSize: 18,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          ระบบงานขนส่ง
        </div>
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: "space-around", marginTop: "10px" }}
        >
          {menu_transport?.map((ele, index) => (
            <Grid
              item
              key={index}
              xs={6}
              md={3}
              lg={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  borderBottom: `4px solid ${ele.color}`,
                  bgcolor: "white",
                  padding: "20px",
                  width: "120px",
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: 5,
                }}
                onClick={() => {
                  if (!R.isNil(ele.link)) {
                    navigate(ele.link);
                  }

                  if (!R.isNil(ele.onClick)) {
                    ele.onClick();
                  }
                }}
              >
                <div>
                  <img
                    src={ele.image}
                    alt={ele.name}
                    draggable={false}
                    width={70}
                    height={70}
                  />
                </div>

                <div style={{ fontSize: 12, textAlign: "center" }}>
                  {ele?.label}
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  const renderTrade = () => {
    return (
      <Box sx={{ width: "100%", typography: "body1", paddingBottom: "15px" }}>
        <div
          style={{
            backgroundColor: "#ffc675",
            padding: "8px",
            color: "white",
            fontSize: 18,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          ระบบซื้อขาย
        </div>
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: "space-around", marginTop: "10px" }}
        >
          {menu_trade?.map((ele, index) => (
            <Grid
              item
              key={index}
              xs={6}
              md={3}
              lg={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  borderBottom: `4px solid ${ele.color}`,
                  bgcolor: "white",
                  padding: "20px",
                  width: "120px",
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: 5,
                }}
                onClick={() => {
                  if (!R.isNil(ele.link)) {
                    navigate(ele.link);
                  }

                  if (!R.isNil(ele.onClick)) {
                    ele.onClick();
                  }
                }}
              >
                <div>
                  <img
                    src={ele.image}
                    alt={ele.name}
                    draggable={false}
                    width={70}
                    height={70}
                  />
                </div>

                <div style={{ fontSize: 12, textAlign: "center" }}>
                  {ele?.label}
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  const renderTruckRental = () => {
    return (
      <Box sx={{ width: "100%", typography: "body1", paddingBottom: "15px" }}>
        <div
          style={{
            backgroundColor: "#FF69B4",
            padding: "8px",
            color: "white",
            fontSize: 18,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          ระบบเช่ารถ
        </div>
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: "space-around", marginTop: "10px" }}
        >
          {menu_truck_rental?.map((ele, index) => (
            <Grid
              item
              key={index}
              xs={6}
              md={3}
              lg={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  borderBottom: `4px solid ${ele.color}`,
                  bgcolor: "white",
                  padding: "20px",
                  width: "120px",
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: 5,
                }}
                onClick={() => {
                  if (!R.isNil(ele.link)) {
                    navigate(ele.link);
                  }

                  if (!R.isNil(ele.onClick)) {
                    ele.onClick();
                  }
                }}
              >
                <div>
                  <img
                    src={ele.image}
                    alt={ele.name}
                    draggable={false}
                    width={70}
                    height={70}
                  />
                </div>

                <div style={{ fontSize: 12, textAlign: "center" }}>
                  {ele?.label}
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  const renderAdvance = () => {
    return (
      <Box sx={{ width: "100%", typography: "body1", paddingBottom: "15px" }}>
        <div
          style={{
            backgroundColor: "#27ce88",
            padding: "8px",
            color: "white",
            fontSize: 18,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          ขั้นสูง
        </div>
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: "space-around", marginTop: "10px" }}
        >
          {menu_advance?.map((ele, index) => (
            <Grid
              item
              key={index}
              xs={6}
              md={3}
              lg={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  borderBottom: `4px solid ${ele.color}`,
                  bgcolor: "white",
                  padding: "20px",
                  width: "120px",
                  height: "130px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: 5,
                }}
                onClick={() => {
                  if (!R.isNil(ele.link)) {
                    navigate(ele.link);
                  }

                  if (!R.isNil(ele.onClick)) {
                    ele.onClick();
                  }
                }}
              >
                <div>
                  <img
                    src={ele.image}
                    alt={ele.name}
                    draggable={false}
                    width={70}
                    height={70}
                  />
                </div>

                <div style={{ fontSize: 12, textAlign: "center" }}>
                  {ele?.label}
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <div className={styles.container}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <img
            src={HomeImage}
            alt={"truck home"}
            width={"80%"}
            height={"auto"}
          />
        </div>
      </Box>
      <Box sx={{ width: "80%" }}>
        <Grid container spacing={1}>
          <Grid item sm={12} md={12} lg={6}>
            {renderTransport()}
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            {renderTrade()}
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            {renderTruckRental()}
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            {renderAdvance()}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Home;
