import instance from "../../axiosInstance";

const findCompanyName = async (user_code) => {
  try {
    return instance.get(`/api/profile/company-name/${user_code}`);
  } catch (error) {
    console.log("error", error);
  }
};

const getUserProfile = async (profile_id) => {
  try {
    return instance.get(`/api/profile/${profile_id}`);
  } catch (error) {
    console.log("error", error);
  }
};

const getArea = async (param) => {
  try {
    return instance.get(`/api/master/map-area`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const editProfile = (id, data) => {
  try {
    return instance.patch(`/api/profile/${id}`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllDriverInProfile = (profile_id = null, params = {}) => {
  console.log("getAllDriverInProfile param", params);
  try {
    return instance.get(`/api/profile/driver-in-profile/${profile_id}`, {
      params: params,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllSubUserInProfile = async (profile_id, param) => {
  try {
    return instance.get(`/api/profile/sub-user/${profile_id}`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export {
  findCompanyName,
  getUserProfile,
  getArea,
  editProfile,
  getAllDriverInProfile,
  getAllSubUserInProfile,
};
