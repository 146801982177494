import instance from "utils/axiosInstance";

export const getUnpaid = async (params = {}) => {
  return await instance.get(`api/finance/shipper/unpaid`, {
    params: params,
  });
};

export const getInvoice = async (params = {}) => {
  return await instance.get(`api/finance/shipper/invoice`, {
    params: params,
  });
};

export const getInvoiceGroup = async (params = {}) => {
  return await instance.get(`api/finance/shipper/invoice-group`, {
    params: params,
  });
};

export const createInvoice = async (data = {}) => {
  return await instance.post(`api/finance/shipper/invoice`, {
    ...data,
  });
};

export const cancelInvoice = async (invoiceCode) => {
  return await instance.post(
    `api/finance/shipper/invoice/${invoiceCode}/cancel`
  );
};

export const getBillingnote = async (params = {}) => {
  return await instance.get(`api/finance/shipper/billingnote`, {
    params: params,
  });
};

export const getBillingnoteGroup = async (params = {}) => {
  return await instance.get(`api/finance/shipper/billingnote-group`, {
    params: params,
  });
};

export const createBillingNote = async (data) => {
  return await instance.post(`api/finance/shipper/billingnote`, {
    ...data,
  });
};

export const cancelBillingnote = async (billCode) => {
  return await instance.post(
    `api/finance/shipper/billingnote/${billCode}/cancel`
  );
};

export const getPaid = async (params = {}) => {
  return await instance.get(`api/finance/shipper/paid`, {
    params: params,
  });
};

export const getPaidGroup = async (params = {}) => {
  return await instance.get(`api/finance/shipper/paid-group`, {
    params: params,
  });
};

export const createPaid = async (data) => {
  const formData = new FormData();

  data.bill_code.forEach((code, index) => {
    formData.append(`bill_code[${index}]`, code);
  });

  formData.append("actual_date", data.actual_date);
  formData.append("amount_of_transfer_actual", data.amount_of_transfer_actual);
  formData.append("bank_id", data.bank_id);
  formData.append("ref", data.ref);
  formData.append("file", data.file);
  formData.append("receipt_name_id", data.receipt_name_id);
  formData.append("credit_remark", data.credit_remark);

  return await instance.post(`api/finance/shipper/Paid`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelPaid = async (receiptCode) => {
  return await instance.post(`api/finance/shipper/Paid/${receiptCode}/cancel`);
};

export const resendGinkgo = async (receiptCode) => {
  return await instance.post(
    `api/finance/shipper/paid/${receiptCode}/resend-ginkgo`
  );
};
export const editPaymentDueDateShipper = async (body) => {
  return await instance.patch(
    `/api/finance/shipper/invoice-group/edit-payment-due-date`,
    body
  );
};

export const editInvoiceDateShipper = async (body) => {
  return await instance.patch(
    `/api/finance/shipper/invoice-group/edit-invoice-date`,
    body
  );
};
