export const wht_options = [
  { value: `{ wht_rate: 0, wht_service: "Expense" }`, name: "(0%) Expense" },
  {
    value: `{ wht_rate: 1, wht_service: "Transport" }`,
    name: "(1%) Transport",
  },
  {
    value: `{ wht_rate: 1, wht_service: "Insurance" }`,
    name: "(1%) Insurance",
  },
  { value: `{ wht_rate: 1, wht_service: "Interest" }`, name: "(1%) Interest" },
  {
    value: `{ wht_rate: 2, wht_service: "Advertise" }`,
    name: "(2%) Advertise",
  },
  { value: `{ wht_rate: 3, wht_service: "Service" }`, name: "(3%) Service" },
  {
    value: `{ wht_rate: 3, wht_service: "Hire of work" }`,
    name: "(3%) Hire of work",
  },
  { value: `{ wht_rate: 5, wht_service: "Rent" }`, name: "(5%) Rent" },
  {
    value: `{ wht_rate: 5, wht_service: "Commission" }`,
    name: "(5%) Commission",
  },
  {
    value: `{ wht_rate: 3, wht_service: "Copyright" }`,
    name: "(3%) Copyright",
  },
  {
    value: `{ wht_rate: 3, wht_service: "Sales promotions" }`,
    name: "(3%) Sales promotions",
  },
  {
    value: `{ wht_rate: 5, wht_service: "Public Entertainers" }`,
    name: "(5%) Public Entertainers",
  },
  {
    value: `{ wht_rate: 10, wht_service: "Dividend" }`,
    name: "(10%) Dividend",
  },
  {
    value: `{ wht_rate: 10, wht_service: "Rent - Foundation" }`,
    name: "(10%) Rent - Foundation",
  },
];
