import instance from "utils/axiosInstance";

export const getUnpaid = async (params = {}) => {
  return await instance.get(`api/trade-finance/carrier/unpaid`, {
    params: params,
  });
};

export const getInvoice = async (params = {}) => {
  return await instance.get(`api/trade-finance/carrier/invoice`, {
    params: params,
  });
};

export const getInvoiceXlsx = async (params = {}) => {
  return await instance.get(`api/trade-finance/carrier/invoice/xlsx`, {
    params: params,
    responseType: "blob",
  });
};

export const getInvoiceGroup = async (params = {}) => {
  return await instance.get(`api/trade-finance/carrier/invoice-group`, {
    params: params,
  });
};

export const createInvoice = async (data = {}) => {
  const formData = new FormData();

  data.trade_transport_ids.forEach((code, index) => {
    formData.append(`trade_transport_ids[${index}]`, code);
  });
  formData.append("actual_date", data.actual_date);
  formData.append("amount_per_trip", data.amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);

  return await instance.post(`api/trade-finance/carrier/invoice`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelInvoice = async (invoiceCode) => {
  return await instance.post(
    `api/trade-finance/carrier/invoice/${invoiceCode}/cancel`
  );
};

export const getBillingnote = async (params = {}) => {
  return await instance.get(`api/trade-finance/carrier/billingnote`, {
    params: params,
  });
};

export const getBillingnoteGroup = async (params = {}) => {
  return await instance.get(`api/trade-finance/carrier/billingnote-group`, {
    params: params,
  });
};

export const createBillingNote = async (data) => {
  const formData = new FormData();

  data.invoice_codes.forEach((code, index) => {
    formData.append(`invoice_codes[${index}]`, code);
  });
  formData.append("actual_date", data.actual_date);
  formData.append("amount_per_trip", data.amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);

  return await instance.post(
    `api/trade-finance/carrier/billingnote`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const cancelBillingnote = async (billCode) => {
  return await instance.post(
    `api/trade-finance/carrier/billingnote/${billCode}/cancel`
  );
};

export const getPaid = async (params = {}) => {
  return await instance.get(`api/trade-finance/carrier/paid`, {
    params: params,
  });
};

export const getPaidGroup = async (params = {}) => {
  return await instance.get(`api/trade-finance/carrier/paid-group`, {
    params: params,
  });
};

export const createPaid = async (data) => {
  const formData = new FormData();

  data.bill_codes.forEach((code, index) => {
    formData.append(`bill_codes[${index}]`, code);
  });

  formData.append("actual_date", data.actual_date);
  formData.append("amount_per_trip", data.amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("amount_of_transfer_actual", data.amount_of_transfer_actual);
  formData.append("bank_id", data.bank_id);
  formData.append("ref", data.ref);
  formData.append("file", data.file);
  formData.append("receipt_voucher_code", data.receipt_voucher_code);

  return await instance.post(`api/trade-finance/carrier/paid`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelPaid = async (receiptCode) => {
  return await instance.post(
    `api/trade-finance/carrier/paid/${receiptCode}/cancel`
  );
};

export const createWht = async (params) => {
  return await instance.get(`/api/trade-finance/carrier/paid/wht`, {
    params: params,
    responseType: "blob",
  });
};
