import instance from "utils/axiosInstance";

export const getUnpaid = async (params = {}) => {
  return await instance.get(`api/rental-finance/tenant/unpaid`, {
    params: params,
  });
};

export const getInvoice = async (params = {}) => {
  return await instance.get(`api/rental-finance/tenant/invoice`, {
    params: params,
  });
};

export const getInvoiceGroup = async (params = {}) => {
  return await instance.get(`api/rental-finance/tenant/invoice-group`, {
    params: params,
  });
};

export const createInvoice = async (data = {}) => {
  return await instance.post(`api/rental-finance/tenant/invoice`, {
    ...data,
  });
};

export const cancelInvoice = async (invoiceCode) => {
  return await instance.post(
    `api/rental-finance/tenant/invoice/${invoiceCode}/cancel`
  );
};

export const getBillingnote = async (params = {}) => {
  return await instance.get(`api/rental-finance/tenant/billingnote`, {
    params: params,
  });
};

export const getBillingnoteGroup = async (params = {}) => {
  return await instance.get(`api/rental-finance/tenant/billingnote-group`, {
    params: params,
  });
};

export const createBillingNote = async (data) => {
  return await instance.post(`api/rental-finance/tenant/billingnote`, {
    ...data,
  });
};

export const cancelBillingnote = async (billCode) => {
  return await instance.post(
    `api/rental-finance/tenant/billingnote/${billCode}/cancel`
  );
};

export const getPaid = async (params = {}) => {
  return await instance.get(`api/rental-finance/tenant/paid`, {
    params: params,
  });
};

export const getPaidGroup = async (params = {}) => {
  return await instance.get(`api/rental-finance/tenant/paid-group`, {
    params: params,
  });
};

export const createPaid = async (data) => {
  const formData = new FormData();

  data.bill_codes.forEach((code, index) => {
    formData.append(`bill_codes[${index}]`, code);
  });

  formData.append("actual_date", data.actual_date);
  formData.append("amount_per_trip", data.amount_per_trip);
  formData.append("vat_per_trip", data.vat_per_trip);
  formData.append("total_per_trip", data.total_per_trip);
  formData.append("decimal", data.decimal);
  formData.append("amount_of_transfer_actual", data.amount_of_transfer_actual);
  formData.append("bank_id", data.bank_id);
  formData.append("ref", data.ref);
  formData.append("file", data.file);
  formData.append("credit_remark", data.credit_remark);
  formData.append("payment_due_date", data.payment_due_date);
  formData.append("unit_price", data.unit_price);
  formData.append("wht", data.wht);

  return await instance.post(`api/rental-finance/tenant/paid`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelPaid = async (receiptCode) => {
  return await instance.post(
    `api/rental-finance/tenant/paid/${receiptCode}/cancel`
  );
};

export const resendGinkgo = async (receiptCode) => {
  return await instance.post(
    `api/rental-finance/tenant/paid/${receiptCode}/resend-ginkgo`
  );
};

export const editPaymentDueDateTenant = async (body) => {
  return await instance.patch(
    `/api/rental-finance/tenant/invoice-group/edit-payment-due-date`,
    body
  );
};

export const editActualDateTenant = async (body) => {
  return await instance.patch(
    `/api/rental-finance/tenant/invoice-group/edit-actual-date`,
    body
  );
};
