import React, { useEffect, useState } from "react";
import SubMenu from "components/layout/SubMenu.js";
import { subMenuMasterData } from "utils/submenuoptions";
import "./ThirdPartyKey.css";
import {
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import FieldSelect from "components/field/FieldSelect";
import { Search } from "@mui/icons-material";
import helper from "utils/helper";
import TableCustomThirdParty from "components/table/ThirdPartyTable/TableCustomThirdParty";
import { Check, Clear } from "@mui/icons-material";
import FloatingButton from "components/button/FloatingButton";
import ModalWork from "components/work/modalWork";
import Line from "components/layout/line";
import { useForm, Controller } from "react-hook-form";
import InputNormal from "components/input/inputNormal";
import API from "utils/api/masterData/thirdPartyKey";
import Swal from "sweetalert2";
import TableActiveIcon from "components/tableActiveIcon";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import SelectRowValue from "components/button/SelectRowValue";
const api = API.create();
const columns = [
  {
    id: "createdAt",
    label: <label style={{ fontSize: 14 }}>วันที่สร้างรายการ</label>,
    align: "center",
    //   width: "13%",
    format: (value) => (
      <label style={{ fontSize: 12 }}>
        {helper.ToConvertDateTimeForTable(value.createdAt)}
      </label>
    ),
  },
  {
    id: "updatedAt",
    label: <label style={{ fontSize: 14 }}>วันที่แก้ไขข้อมูลล่าสุด</label>,
    align: "center",
    //   width: "13%",
    format: (value) => (
      <label style={{ fontSize: 12 }}>
        {helper.ToConvertDateTimeForTable(value.updatedAt)}
      </label>
    ),
  },
  {
    id: "name",
    label: <label style={{ fontSize: 14 }}>ชื่อบริการ</label>,
    align: "center",
    //   width: "13%",
    format: (value) => <label style={{ fontSize: 12 }}>{value.name}</label>,
  },
  {
    id: "usage",
    label: <label style={{ fontSize: 14 }}>การใช้งาน</label>,

    align: "center",
    //   width: "13%",
    format: (value) => (
      <label style={{ fontSize: 12 }}>
        {[
          { value: "mobile", name: "สำหรับ Mobile App" },
          { value: "web", name: "สำหรับ Web App" },
          { value: "api", name: "สำหรับ Api" },
          { value: "all", name: "ทั้งหมด" },
        ].filter((ele) => ele.value === value.usage).length > 0
          ? [
              { value: "mobile", name: "สำหรับ Mobile App" },
              { value: "web", name: "สำหรับ Web App" },
              { value: "api", name: "สำหรับ Api" },
              { value: "all", name: "ทั้งหมด" },
            ].filter((ele) => ele.value === value.usage)[0].name
          : "-"}
      </label>
    ),
  },
  {
    id: "param",
    label: <label style={{ fontSize: 14 }}>ชื่อตัวแปร</label>,

    align: "center",
    //   width: "13%",
    format: (value) => <label style={{ fontSize: 12 }}>{value.param}</label>,
  },
  {
    id: "project_name",
    label: <label style={{ fontSize: 14 }}>สถานะใช้งาน</label>,

    align: "center",
    //   width: "13%",
    format: (value) => <TableActiveIcon active_status={value.active} />,
  },
];

export default function ThirdPartyKey() {
  const [filter, setFilter] = useState({
    order_by: "active",
    order_by_value: 1,
    search_type: "name",
  });
  const [filterPerPage, setFilterPerPage] = useState([
    { value: "5", name: "5" },
  ]);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 5,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm({});
  useEffect(() => {
    if (refresh) {
      getKey();
    }
  }, [refresh]);

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`Third Party Key`}
        menus={subMenuMasterData.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `Third Party Key` ? true : false,
          };
        })}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "Third Party Key",
            path: null,
          },
        ]}
      />
    );
  };
  const getKey = async (page = pagination) => {
    let fil = { ...filter, ...page };
    console.log(fil);
    await api
      .getKey(fil)
      .then((response) => {
        console.log(response);
        setData(response.data.results);
        let PerPage = [{ value: "5", name: "5" }];
        if (response.data.total > 5) {
          PerPage.push({ value: "10", name: "10" });
        }
        if (response.data.total > 10) {
          PerPage.push({ value: "15", name: "15" });
        }
        if (response.data.total > 15) {
          PerPage.push({ value: "20", name: "20" });
        }
        if (response.data.total > 20) {
          PerPage.push({ value: "50", name: "50" });
        }
        if (response.data.total > 50) {
          PerPage.push({ value: "100", name: "100" });
        }
        if (response.data.total > 100) {
          PerPage.push({ value: "250", name: "250" });
        }
        if (response.data.total > 250) {
          PerPage.push({ value: "500", name: "500" });
        }
        if (response.data.total > 500) {
          PerPage.push({ value: "1000", name: "1000" });
        }
        setFilterPerPage(PerPage);
        setPagination({
          page: response.data.page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        setRefresh(false);
      })
      .catch((error) => {
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text:
            error.response.data.description?.message ??
            error.response.data.description ??
            "",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };
  const add = async (detail) => {
    console.log(detail);
    await api
      .createKey(detail)
      .then((response) => {
        // console.log(response);
        Swal.fire({
          // position: 'top-end',
          icon: "success",
          title: "เพิ่ม Key เสร็จสิ้น",
          showConfirmButton: false,
          timer: 1500,
          willClose: () => {
            // window.location.href = "/work";
            reset();
            setCreateModal(false);
            setRefresh(true);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text:
            error.response.data.description?.message ??
            error.response.data.description ??
            "",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };

  const edit = async (detail) => {
    console.log(detail);
    await api
      .editKey(detail, editModal)
      .then((response) => {
        // console.log(response);
        Swal.fire({
          // position: 'top-end',
          icon: "success",
          title: "แก้ไข Key เสร็จสิ้น",
          showConfirmButton: false,
          timer: 1500,
          willClose: () => {
            // window.location.href = "/work";
            reset();
            setEditModal(null);
            setRefresh(true);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text:
            error.response.data.description?.message ??
            error.response.data.description ??
            "",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };
  return (
    <div className="third-party-body">
      {renderSubMenu()}

      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "15px 20px 10px 20px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid
          container
          rowSpacing={2}
          justifyContent="space-between"
          // width={"100%"}
        >
          <Grid item xs={12} sm={6} md={6} lg={3} className="gridItem">
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "active", name: "สถานะใช้งาน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={filter.order_by}
              value={filter.order_by}
              onChange={(val) => {
                setFilter({ ...filter, order_by: val });
              }}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} className="gridItem">
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "name", name: "ชื่อบริการ" },
                { value: "usage", name: "การใช้งาน" },
                { value: "param", name: "ชื่อตัวแปร" },
                { value: "key", name: "Key ที่ใช้งาน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={filter.search_type}
              value={filter.search_type}
              onChange={(val) => {
                setFilter({ ...filter, search_type: val });
              }}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} className="gridItem">
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_value}
              onChange={(val) => {
                setFilter({ ...filter, search_value: val.target.value });
              }}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
            className="gridItem"
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "40px",
                height: "40px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                getKey({ ...pagination, page: 1 });
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 30 }} />
            </Avatar>

            <div>
              <SelectRowValue
                onSelect={(val) => {
                  setPagination({ ...pagination, per_page: val });
                  getKey({ ...pagination, per_page: val });
                }}
              />
              {/* <FieldSelect
                name={"จำนวนแถว"}
                options={filterPerPage}
                style={{
                  // width: "-webkit-fill-available",
                  width: 80,
                }}
                defaultValue={"5"}
                value={pagination.per_page}
                onChange={(val) => {
                  setPagination({ ...pagination, per_page: val });
                  getKey({ ...pagination, per_page: val });
                }}
                closePaddingBottom={true}
              /> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <TableCustomThirdParty
        columns={columns}
        dataSource={data}
        pagination={pagination}
        setPagination={(val) => {
          setPagination(val);
          getKey(val);
        }}
        onClick={(val) => {
          setValue("name", val.name);
          setValue("usage", val.usage);
          setValue("param", val.param);
          setValue("key", val.key);
          setValue("active", val.active);
          setValue("type", val.type);

          setEditModal(val.id);
        }}
        refresh={refresh}
      />
      <FloatingButton
        button_type="add"
        onClick={() => {
          setCreateModal(true);
        }}
      />
      <ModalWork
        open={createModal}
        close={() => setCreateModal(false)}
        enableClose
        // minHeight
      >
        <div style={{ padding: "0px 20px 100px 20px" }}>
          <div style={{ textAlign: "center" }}>เพิ่ม Third Party Key</div>
          <Line opacity={0.6} heigth={3} />
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="ชื่อบริการ*"
                  onChange={onChange}
                  value={value}
                  error={errors.name}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FieldSelect
                  name={"การใช้งาน"}
                  options={[
                    { value: "mobile", name: "สำหรับ Mobile App" },
                    { value: "web", name: "สำหรับ Web App" },
                    { value: "api", name: "สำหรับ Api" },
                    { value: "all", name: "ทั้งหมด" },
                  ]}
                  style={{
                    width: "-webkit-fill-available",
                  }}
                  defaultValue={"all"}
                  value={value}
                  onChange={onChange}
                  closePaddingBottom={true}
                />
              )}
              name="usage"
              defaultValue=""
            />
          </div>
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="ประเภทการใช้งาน"
                  onChange={onChange}
                  value={value}
                  error={errors.type}
                  subFirst={"(เช่น longdomap, onesignal)"}
                />
              )}
              name="type"
              defaultValue=""
            />
          </div>
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="ตัวแปลที่ใช้งาน"
                  onChange={onChange}
                  value={value}
                  error={errors.param}
                  subFirst={"(เช่น KEY_1, KEY_2, SIT, PROD)"}
                />
              )}
              name="param"
              defaultValue=""
            />
          </div>
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="Key*"
                  onChange={onChange}
                  value={value}
                  error={errors.key}
                />
              )}
              name="key"
              defaultValue=""
            />
          </div>
          <div
            style={{
              // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: "0px 40px 20px 0px",
              // borderRadius: 20,
              bottom: 0,
              position: "absolute",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{ height: 50 }}
              color="secondary"
              onClick={handleSubmit(add)}
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </ModalWork>
      <ModalWork
        open={editModal ? true : false}
        close={() => {
          setEditModal(null);
          reset();
        }}
        enableClose
        // minHeight
      >
        <div style={{ padding: "0px 20px 100px 20px" }}>
          <div style={{ textAlign: "center" }}>แก้ไข Third Party Key</div>
          <Line opacity={0.6} heigth={3} />
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="ชื่อบริการ*"
                  onChange={onChange}
                  value={value}
                  error={errors.name}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FieldSelect
                  name={"การใช้งาน"}
                  options={[
                    { value: "mobile", name: "สำหรับ Mobile App" },
                    { value: "web", name: "สำหรับ Web App" },
                    { value: "api", name: "สำหรับ Api" },
                    { value: "all", name: "ทั้งหมด" },
                  ]}
                  style={{
                    width: "-webkit-fill-available",
                  }}
                  defaultValue={"all"}
                  value={value}
                  onChange={onChange}
                  closePaddingBottom={true}
                />
              )}
              name="usage"
              defaultValue=""
            />
          </div>
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="ประเภทการใช้งาน"
                  onChange={onChange}
                  value={value}
                  error={errors.type}
                  subFirst={"(เช่น longdomap, onesignal)"}
                />
              )}
              name="type"
              defaultValue=""
            />
          </div>
          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="ตัวแปลที่ใช้งาน*"
                  onChange={onChange}
                  value={value}
                  error={errors.param}
                  // subFirst={"(ไม่จำเป็นต้องกรอก)"}
                  subFirst={"(เช่น KEY_1, KEY_2, SIT, PROD)"}
                />
              )}
              name="param"
              defaultValue=""
            />
          </div>

          <div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="Key*"
                  onChange={onChange}
                  value={value}
                  error={errors.key}
                />
              )}
              name="key"
              defaultValue=""
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Controller
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FieldSelect
                  name={"การใช้งาน"}
                  options={[
                    { value: true, name: "ใช้งาน" },
                    { value: false, name: "ไม่ใช้งาน" },
                  ]}
                  style={{
                    width: "-webkit-fill-available",
                  }}
                  value={value}
                  onChange={onChange}
                  closePaddingBottom={true}
                />
              )}
              name="active"
              defaultValue=""
            />
          </div>
          <div
            style={{
              // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: "0px 40px 20px 0px",
              // borderRadius: 20,
              bottom: 0,
              position: "absolute",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{ height: 50 }}
              color="secondary"
              onClick={handleSubmit(edit)}
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </ModalWork>
    </div>
  );
}
