import { Grid, Typography } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import React, { useEffect, useState } from "react";
import { getCountUser } from "utils/api/user/user";

const TrackingUser = () => {
  const [loading, setLoading] = useState(false);
  const [allUserApproved, setAllUserApproved] = useState(null);
  const menus = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: "ติดตามสถานะเอกสารผู้ใช้งานทั้งหมด",
      path: "/user-tracking",
      active: true,
    },
    {
      label: "ติดตามคนขับทั้งหมด",
      path: "/tracking-driver",
    },
  ];
  const breadcrumbs = [
    {
      label: "ติดตามสถานะเอกสารผู้ใช้งานทั้งหมด",
      path: null,
    },
  ];

  useEffect(() => {
    CountApprovedUser();
  }, []);
  console.log("allUserApproved", allUserApproved);
  const CountApprovedUser = async () => {
    setLoading(true);
    await getCountUser()
      .then((res) => {
        setLoading(false);
        console.log("count all work", res);
        setAllUserApproved(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <div style={{ height: "90vh" }}>
      {loading && <Loading />}
      <div className="post-work-body">
        <div className="post-work-body-flex">
          <div style={{ width: 400 }}>
            <SubMenu menus={menus} />
          </div>
          <div style={{ flex: 1, alignSelf: "center" }}>
            <SubMenu breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div
          style={{
            // padding: "20px 0",
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" sx={{ padding: "10px" }}>
            ลูกค้า
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ maxWidth: "70vw" }}
          >
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#2499EF",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.shipper?.shipper_pending ?? 0}
                </div>
                <div className="status-work">รอการอนุมัติ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#27CE88",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.shipper?.shipper_approved ?? 0}
                </div>
                <div className="status-work">อนุมัติแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FFC675",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.shipper?.shipper_edit ?? 0}
                </div>
                <div className="status-work">แก้ไขเพิ่มเติม</div>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FF316F",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.shipper?.shipper_rejected ?? 0}
                </div>
                <div className="status-work">ไม่อนุมัติ</div>
              </div>
            </Grid> */}
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#8CA3BA",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.shipper?.shipper_expired ?? 0}
                </div>
                <div className="status-work">เอกสารหมดอายุ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#CC99FF",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.shipper?.shipper_unstatus ?? 0}
                </div>
                <div className="status-work">ยังไม่อัพเดตสถานะ</div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            // padding: "20px 0",
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" sx={{ padding: "10px" }}>
            ผู้ขนส่ง
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ maxWidth: "70vw" }}
          >
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#2499EF",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.carrier?.carrier_pending ?? 0}
                </div>
                <div className="status-work">รอการอนุมัติ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#27CE88",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.carrier?.carrier_approved ?? 0}
                </div>
                <div className="status-work">อนุมัติแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FFC675",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.carrier?.carrier_edit ?? 0}
                </div>
                <div className="status-work">แก้ไขเพิ่มเติม</div>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FF316F",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.carrier?.carrier_rejected ?? 0}
                </div>
                <div className="status-work">ไม่อนุมัติ</div>
              </div>
            </Grid> */}
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#8CA3BA",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.carrier?.carrier_expired ?? 0}
                </div>
                <div className="status-work">เอกสารหมดอายุ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#CC99FF",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.carrier?.carrier_unstatus ?? 0}
                </div>
                <div className="status-work">ยังไม่อัพเดตสถานะ</div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            // padding: "20px 0",
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h2" sx={{ padding: "10px" }}>
            คนขับ
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ maxWidth: "70vw" }}
          >
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#2499EF",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.driver?.driver_pending ?? 0}
                </div>
                <div className="status-work">รอการอนุมัติ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#27CE88",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.driver?.driver_approved ?? 0}
                </div>
                <div className="status-work">อนุมัติแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FFC675",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.driver?.driver_edit ?? 0}
                </div>
                <div className="status-work">แก้ไขเพิ่มเติม</div>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FF316F",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.driver?.driver_rejected ?? 0}
                </div>
                <div className="status-work">ไม่อนุมัติ</div>
              </div>
            </Grid> */}
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#8CA3BA",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.driver?.driver_expired ?? 0}
                </div>
                <div className="status-work">เอกสารหมดอายุ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#CC99FF",
                }}
              >
                <div className="count-number">
                  {allUserApproved?.driver?.driver_unstatus ?? 0}
                </div>
                <div className="status-work">ยังไม่อัพเดตสถานะ</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default TrackingUser;
