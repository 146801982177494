import React from "react";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Button,
} from "@mui/material";
import ButtonCustom from "components/ButtonCustom";

const CustomModal = ({
  open = false,
  title = "",
  onConfirm = () => {},
  onCancel = () => {},
  cancelTextBtn = "ย้อนกลับ",
  confirmTextBtn = "ยืนยัน",
  disabledCancelBtn = false,
  disabledComfirmBtn = false,
  children = null,
  contentFullWidth = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{
        "& .MuiPaper-root": {
          minWidth: "466px",
          minHeight: "218px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle sx={{ fontSize: "20px", pt: "31px", pl: 0, pr: 0, pb: 0 }}>
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          alignItems: "center",
          p: 0,
          display: "flex",
          width: contentFullWidth ? "100%" : "auto",
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          pb: `30px`,
          width: `100%`,
          display: `flex`,
          justifyContent: `space-around`,
        }}
      >
        {/* <ButtonCustom
          type={`cancle`}
          disabled={disabledCancelBtn}
          onClick={onCancel}
          name={cancelTextBtn}
        />

        <ButtonCustom
          type={`submit`}
          disabled={disabledComfirmBtn}
          onClick={onConfirm}
          name={confirmTextBtn}
        /> */}

        <Box className={`text-center`}>
          <Button
            variant="outlined"
            disabled={disabledCancelBtn}
            color="secondary"
            style={{
              width: "160px",
              height: "50px",
              borderRadius: "10px",
              border: "1px solid #8CA3BA",
              color: "#8CA3BA",
            }}
            onClick={onCancel}
          >
            {cancelTextBtn}
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={disabledComfirmBtn}
            color="primary"
            onClick={onConfirm}
            style={{
              width: "160px",
              height: "50px",
              borderRadius: "10px",
              border: "1px solid #8CA3BA",
              marginLeft: "15px",
            }}
          >
            {confirmTextBtn}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
