import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useEffect, useState } from "react";
import "../finance.css";
import { subMenuFinance } from "utils/submenuoptions";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import AdvanceSearchButton from "components/button/AdvanceSearchButton";
import { getPaid } from "utils/api/finance/shipper";
import API from "../../../utils/api/work/work";
import SelectRowValue from "components/button/SelectRowValue";

const ShipperReceipt = () => {
  const apiWork = API.create();
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "receipt_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "work_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  //datatable
  const [formSearchOld, setFormSearchOld] = useState({});
  const [isBusy, setIsBusy] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);

  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuFinance]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setActiveSubMenu(5);
    setSubMenuBreadcrumbs([
      {
        label: "ข้อมูลการเงิน",
        path: null,
      },
      {
        label: "ข้อมูลการเงินลูกค้า",
        path: null,
      },
      {
        label: "ชำระแล้ว",
        path: null,
      },
    ]);
    handleSearch();
    getStatus();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const getStatus = async () => {
    apiWork
      .getWorkStatus()
      .then((response) => {
        console.log(response);
        setWorkStatus(
          response.data.results.map((ele) => {
            return { ...ele, name: ele.name, value: ele.id };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      work_code: "",
      shipper_name: "",
      project_name: "",
      invoice_code: "",
      bill_code: "",
      receipt_code: "",
      search: "",
      profile_type:
        formdata.profile_type !== "ALL" ? formdata.profile_type : "",
      payment_type:
        formdata.payment_type !== "ALL" ? formdata.payment_type : "",
      work_status: formdata.work_status !== "ALL" ? formdata.work_status : "",
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getPaid(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;

        const updatedResult = result;
        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        error.response.data.description
      );
    }
  };

  const handleOnReset = () => {
    setFormSearch({
      ...formSearch,
      profile_type: "ALL",
      payment_type: "ALL",
      work_status: "ALL",
    });
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "receipt_date", name: "วันที่ชำระล่าสุด" },
                { value: "finish_date", name: "วันที่เสร็จสิ้นงาน" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "work_code", name: "เลขที่งาน" },
                { value: "project_name", name: "ชื่อโครงการ" },
                { value: "shipper_name", name: "ชื่อโปรไฟล์ลูกค้า" },
                { value: "invoice_code", name: "เลขที่ใบแจ่้งหนี้" },
                { value: "bill_code", name: "เลขที่ใบวางบิล" },
                { value: "receipt_code", name: "เลขที่ใบเสร็จ" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <SearchButton onClick={handleSearch} />
            <FormControl>
              <SelectRowValue
                onSelect={(e) => {
                  setPagination({ ...pagination, per_page: e });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
            <AdvanceSearchButton
              onReset={handleOnReset}
              onSearch={handleSearch}
              value={formSearch}
            >
              {renderFormAdvSearch()}
            </AdvanceSearchButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormAdvSearch = () => {
    return (
      <>
        <form>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "receipt_date", name: "วันที่ชำระล่าสุด" },
                { value: "finish_date", name: "วันที่เสร็จสิ้นงาน" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
            />
          </FormControl>
          <FieldDateRangePicker
            className="FieldDateRangePicker-drawer w-100"
            popupClassName="FieldDateRangePicker-drawer-popup"
            name={`วันเริ่มต้น - วันสิ้นสุด`}
            value={{
              start_date: formSearch.dateFrom,
              end_date: formSearch.dateTo,
            }}
            onChange={(e) => handleOnChangeSearch(e, "date")}
            style={{ marginBottom: "23px" }}
          />
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทลูกค้า`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "บุคคลธรรมดา" },
                { value: "1", name: "นิติบุคคล" },
              ]}
              value={formSearch.profile_type}
              onChange={(e) => handleOnChangeSearch(e, "profile_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทการชำระเงินงตาม`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "เงินสด" },
                { value: "1", name: "เครดิต" },
              ]}
              value={formSearch.payment_type}
              onChange={(e) => handleOnChangeSearch(e, "payment_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`สถานะงาน`}
              options={[{ value: "ALL", name: "ทั้งหมด" }, ...workStatus]}
              value={formSearch.work_status}
              onChange={(e) => handleOnChangeSearch(e, "work_status")}
            />
          </FormControl>
        </form>
      </>
    );
  };

  const renderTable = () => {
    const fields = [
      {
        key: "receipt_date",
        label: "วันที่ชำระ",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "receipt_code",
        label: "เลขที่งาน",
        tdclass: "text-center",
        render: "receipt",
      },
      {
        key: "shipper_name",
        label: "ชื่อโปรไฟล์ลูกค้า",
      },
      {
        key: "shipper_profile_type",
        label: "ประเภทโปรไฟล์",
        tdclass: "text-center",
      },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      {
        key: "shipper_payment_type",
        label: "ประเภทการชำระเงิน",
        tdclass: "text-center",
      },
      {
        key: "work_status",
        label: "สถานะงาน",
      },
      {
        key: "finish_date",
        label: "วันที่เสร็จสิ้นงาน",
        tdclass: "text-center",
        render: "date",
      },
      {
        key: "shipper_freight_before_tax",
        label: "ราคาลูกค้าก่อนหักภาษี",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "shipper_freight_tax",
        label: "ภาษีหัก ณ ที่จ่าย",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "shipper_freight_net",
        label: "ราคาลูกค้าสุทธิ",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "receipt_tax_id",
        label: "รายละเอียด",
        render: "receipt_detail",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
        />
      </>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <div className="Finance ShipperReceipt">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default ShipperReceipt;
