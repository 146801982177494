import axios from "axios";
import { withCookies, Cookies } from "react-cookie";
import helper from "utils/helper";
import qs from "qs";

const setAPI = async () => {
  // console.log(ENV.API_ENDPOINT_01);
  return axios.create({
    baseURL: process.env.REACT_APP_API_ENPOINT,
    timeout: 100000,
  });
};

const create = () => {
  const project = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/project" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });

    // helper.getCookie("access_token")
  };

  const getUser = async (data) => {
    let api = await setAPI();
    return api.get("api/work/company-name/" + data.shipper_code, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const getRoute = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/get-route-fuel-rate" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const getGeneralPrice = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/get-general-price" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const postWork = async (data) => {
    let api = await setAPI();
    return api.post("api/work/manual-work", data, {
      headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const importWork = async (data) => {
    let api = await setAPI();
    return api.post("api/work/import-work", data, {
      headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const getWork = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/get-all-work" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const getWorkDetail = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/get-work" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const getWorkStatus = async () => {
    let api = await setAPI();
    return api.get("api/work/get-work-status", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const acceptWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    return api.patch("api/work/accept-work" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const pickupSendWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    return api.patch("api/work/pickup-send-work" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const documentReturnWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    return api.patch("api/work/document-return-work" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const problemWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    console.log("api/work/problem-work" + param);
    return api.patch("api/work/problem-work" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const updateWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    return api.patch("api/work/update-status" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const assignWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    return api.patch("api/work/assign-work" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const cancelWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    // param += "&" + helper.getParam(data);
    return api.delete("api/work/cancel-work" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
      data: data,
    });
  };

  const deletelWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    // param += "&" + helper.getParam(data);
    return api.delete("api/work/delete-work" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
      data: data,
    });
  };

  const expireWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    // param += "&" + helper.getParam(data);
    return api.delete("api/work/expire-work" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
      data: data,
    });
  };
  const getTracking = async (data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    param += "&" + helper.getParam(data);
    return api.get("api/work/tracking-driver-all" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const editWork = async (work_id, data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    console.log("work_id", work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    return api.patch("api/work/edit-work" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const getDriverLoc = async (data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    param += "&" + helper.getParam(data);
    return api.get("api/work/driver-location" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const returnToOrder = async (work_id) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    work_id.forEach((element, index) => {
      if (index + 1 !== work_id.length) {
        param += "work_id=" + element + "&";
      } else {
        param += "work_id=" + element;
      }
    });
    return api.patch(
      "api/work/back-to-order" + param,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + helper.getCookie("access_token"),
        },
      }
    );
  };

  const getWorkDetailPublic = async (data) => {
    let api = await setAPI();
    let param = "?";
    // param += helper.getParam(work_id);
    param += "&" + helper.getParam(data);
    return api.get("api/work/public/get-work-public" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const getDetailPayment = async (status_id, document_code, type) => {
    let api = await setAPI();
    return api.get(
      `/api/finance/${type}/user/payment_detail/${status_id}/${document_code}`,
      {
        headers: {
          Authorization: "Bearer " + helper.getCookie("access_token"),
          "Content-Type": "application/json",
        },
      }
    );
  };

  const rateUser = async (work_id, data) => {
    let api = await setAPI();
    let param = "?work_id=" + work_id;
    return api.patch("api/work/rate-work" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const adminNote = async (work_id, data) => {
    let api = await setAPI();
    let param = "?work_id=" + work_id;
    return api.patch("api/work/admin-note" + param, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const downloadTemplate = async () => {
    let api = await setAPI();
    return api.get(`api/work/download-template-import-work`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "application/json",
        responseType: "blob",
      },
      responseType: "blob",
    });
  };

  const downloadTemplateTemplate = async () => {
    let api = await setAPI();
    return api.get(`api/work/download-template-import-template`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "application/json",
        responseType: "blob",
      },
      responseType: "blob",
    });
  };

  const getTrackingFromCode = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += "&" + helper.getParam(data);
    return api.get(`api/work/get-tracking` + param, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "application/json",
      },
    });
  };

  const countAllWork = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += "&" + helper.getParam(data);
    return api.get(`/api/work/count-all-work` + param, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "application/json",
      },
    });
  };

  const getGeneralPricePublic = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/public/get-general-price" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
  const routeOptimiza = async (data) => {
    try {
      // link docs = https://api.longdo.com/map/doc/rest.php#TSP
      const resp = await axios({
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: "https://api.longdo.com/route-tsp-v2",
      });

      if (resp.data.status === 200) {
        return {
          code: 1000,
          description: `success`,
          data: resp.data.body,
        };
      } else {
        throw {
          code: resp.data.status,
          description: resp.data.message,
        };
      }
    } catch (error) {
      throw error;
    }
  };
  const getTemplate = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/get-all-template" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const getTemplateById = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/get-template" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const editTemplate = async (data) => {
    let api = await setAPI();
    return api.patch("api/work/edit-template", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const importTemplate = async (data) => {
    let api = await setAPI();
    return api.post("api/work/import-template", data, {
      headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const predictPrice = async (param) => {
    let api = await setAPI();
    return api.get("api/work/get-work-price-predict", {
      params: param,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const getTemplateCarrierFreight = async (data) => {
    console.log("getTemplateCarrierFreight", data);
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/work/get-template-carrier-freight" + param, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const downloadTemplateDocumentNumber = async () => {
    let api = await setAPI();
    return api.get(`/api/work/get-template-document-number`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "application/json",
        responseType: "blob",
      },
      responseType: "blob",
    });
  };
  const uploadDocumentNumber = async (data) => {
    let api = await setAPI();
    return api.post(`/api/work/update-template-document-number`, data, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const checkDuplicateWork = async () => {
    let api = await setAPI();
    return api.get(`/api/work/work_duplicate_accept_work`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
        "Content-Type": "multipart/form-data",
      },
    });
  };

  return {
    project,
    getUser,
    getRoute,
    getGeneralPrice,
    postWork,
    importWork,
    getWork,
    getWorkStatus,
    getWorkDetail,
    acceptWork,
    updateWork,
    assignWork,
    cancelWork,
    deletelWork,
    expireWork,
    pickupSendWork,
    documentReturnWork,
    problemWork,
    getTracking,
    editWork,
    getDriverLoc,
    returnToOrder,
    getWorkDetailPublic,
    getDetailPayment,
    rateUser,
    adminNote,
    downloadTemplate,
    getTrackingFromCode,
    countAllWork,
    getGeneralPricePublic,
    routeOptimiza,
    getTemplate,
    importTemplate,
    predictPrice,
    downloadTemplateTemplate,
    getTemplateCarrierFreight,
    getTemplateById,
    editTemplate,
    downloadTemplateDocumentNumber,
    uploadDocumentNumber,
    checkDuplicateWork,
  };
};

export default { create };
