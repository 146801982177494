// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TruckRental_gridItem__2NraF {
  padding-right: 20px;
}

.TruckRental_modalStyle__wMkwd {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 30vw;
  min-height: 95vh;
  max-height: 95vh;
  padding: 25px;
  /* border: 2px solid #000; */
}

.TruckRental_titleUserRole__5797L {
  width: 100%;
  padding: 13px;
  text-align: center;
  border-bottom: 2.5px solid var(--mui-palette-grey-main);
}

.TruckRental_containerDetail__8UDwo {
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: 0;
  gap: 40px;
  padding-left: 97px;
  padding-right: 97px;
}
.TruckRental_cardDetail__CjB38 {
  background-color: white;
  border-radius: 20px;
  width: 400px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 25px 10px 25px;
  min-height: 80.5vmin;
  max-height: 80.5vmin;
  overflow: auto;
  /* height: 100%; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/truckRental/TruckRental.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,SAAS;EACT,SAAS;EACT,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,4BAA4B;EAC5B,oBAAoB;EACpB,oBAAoB;EACpB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".gridItem {\n  padding-right: 20px;\n}\n\n.modalStyle {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  min-width: 30vw;\n  min-height: 95vh;\n  max-height: 95vh;\n  padding: 25px;\n  /* border: 2px solid #000; */\n}\n\n.titleUserRole {\n  width: 100%;\n  padding: 13px;\n  text-align: center;\n  border-bottom: 2.5px solid var(--mui-palette-grey-main);\n}\n\n.containerDetail {\n  display: flex;\n  flex-direction: row;\n  overflow: auto;\n  margin: 0;\n  gap: 40px;\n  padding-left: 97px;\n  padding-right: 97px;\n}\n.cardDetail {\n  background-color: white;\n  border-radius: 20px;\n  width: 400px;\n  min-width: 400px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 10px 25px 10px 25px;\n  min-height: 80.5vmin;\n  max-height: 80.5vmin;\n  overflow: auto;\n  /* height: 100%; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridItem": `TruckRental_gridItem__2NraF`,
	"modalStyle": `TruckRental_modalStyle__wMkwd`,
	"titleUserRole": `TruckRental_titleUserRole__5797L`,
	"containerDetail": `TruckRental_containerDetail__8UDwo`,
	"cardDetail": `TruckRental_cardDetail__CjB38`
};
export default ___CSS_LOADER_EXPORT___;
