import React, { useRef } from "react";
import { Grid } from "@mui/material";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import SearchButton from "components/button/SearchButton";
import AdvanceSearchButton from "components/button/AdvanceSearchButton";
import IconButton from "components/button/IconButton";
import { Download, Upload } from "@mui/icons-material";
import helper from "utils/helper";
import styles from "./FilterTab.module.css";
import SelectRowValue from "components/button/SelectRowValue";

const FilterTab = ({
  // ================== filter ==================
  sortOption = {
    value: "updated_at",
    options: [{ value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" }],
    onChange: () => {},
  },
  dateRangePickerOption = {
    value: { start_date: `2023-01-02`, end_date: `2024-08-02` },
    onChange: () => {},
  },
  searchFieldOption = {
    value: "",
    options: [{ value: "", name: "" }],
    onChange: () => {},
  },
  searchValueOption = {
    value: null,
    onPressEnter: () => {},
    onChange: () => {},
  },

  // ================== button ==================
  searchButtonOption = {
    className: ``,
    onClick: () => {},
    styles: {},
  },
  downloadButtonOption = {
    className: ``,
    onClick: () => {},
    styles: {},
  },
  uploadButtonOption = {
    className: ``,
    onClick: () => {},
    styles: {},
  },
  perPageSelectedOption = {
    value: "",
    onChange: () => {},
  },
  advanceSearchButtonOption = {
    classNames: ``,
    styles: {},
    onSearch: () => {},
    onReset: () => {},
    children: null,
  },
  pageName = "",
}) => {
  const fileInputRef = useRef(null);

  const renderGroupFilter = () => {
    return (
      <>
        {!helper.IsEmptyFunction(sortOption.onChange) && (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
            sx={{ paddingRight: "20px" }}
          >
            <FieldSelect
              className={styles.filterTabSection}
              name={`กรองและจัดเรียงตาม`}
              options={sortOption.options}
              value={sortOption.value}
              onChange={(val) => {
                sortOption.onChange("sort", val);
              }}
              closePaddingBottom={true}
            />
          </Grid>
        )}
        {!helper.IsEmptyFunction(dateRangePickerOption.onChange) && (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
            sx={{ paddingRight: "20px" }}
          >
            <FieldDateRangePicker
              className={styles.filterTabSection}
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: dateRangePickerOption.value.start_date,
                end_date: dateRangePickerOption.value.end_date,
              }}
              onChange={(val) => {
                dateRangePickerOption.onChange(`date_duration`, val);
              }}
              closePaddingBottom={true}
            />
          </Grid>
        )}
        {!helper.IsEmptyFunction(searchFieldOption.onChange) && (
          <Grid
            className={styles.SearchSelectWrapper}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
            sx={{ paddingRight: "20px" }}
          >
            <FieldSelect
              className={styles.filterTabSection}
              name={`ค้นหาจาก`}
              options={searchFieldOption.options}
              value={searchFieldOption.value}
              onChange={(val) => {
                searchFieldOption.onChange("search_field", val);
              }}
              closePaddingBottom={true}
            />
          </Grid>
        )}
        {!helper.IsEmptyFunction(searchValueOption.onChange) && (
          <Grid
            className={styles.SearchFieldWrapper}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
            sx={{ paddingRight: "20px" }}
          >
            <FieldSearch
              className={styles.filterTabSection}
              value={searchValueOption.value}
              onChange={(val) => {
                searchValueOption.onChange("search_val", val);
              }}
              onPressEnter={(val) => {
                searchValueOption.onPressEnter("search_val", val);
              }}
              closePaddingBottom={true}
            />
          </Grid>
        )}
      </>
    );
  };
  const renderGroupBtn = () => {
    return (
      <>
        {!helper.IsEmptyFunction(searchButtonOption.onClick) && (
          <Grid item md={"auto"} lg={"auto"}>
            <SearchButton
              classNames={searchButtonOption.className}
              onClick={searchButtonOption.onClick}
              styles={searchButtonOption.styles}
            />
          </Grid>
        )}

        {!helper.IsEmptyFunction(downloadButtonOption.onClick) && (
          <Grid item md={"auto"} lg={"auto"}>
            <IconButton
              classNames={downloadButtonOption.classNames}
              onClick={downloadButtonOption.onClick}
              renderIcon={() => (
                <Download sx={{ fontSize: "40px", color: "#121F43" }} />
              )}
              styles={advanceSearchButtonOption.styles}
            />
          </Grid>
        )}

        {!helper.IsEmptyFunction(uploadButtonOption.onClick) && (
          <Grid item md={"auto"} lg={"auto"}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                uploadButtonOption.onClick(e.target.files[0]);
              }}
            />
            <IconButton
              classNames={uploadButtonOption.classNames}
              onClick={() => {
                fileInputRef.current.click();
                uploadButtonOption.onClick();

                // event.stopPropagation();
              }}
              renderIcon={() => (
                <Upload sx={{ fontSize: "40px", color: "#121F43" }} />
              )}
              styles={advanceSearchButtonOption.styles}
            />
          </Grid>
        )}

        {!helper.IsEmptyFunction(perPageSelectedOption.onChange) && (
          <Grid item md={"auto"} lg={"auto"}>
            <SelectRowValue
              onSelect={(value) => {
                perPageSelectedOption.onChange(value);
              }}
            />
            {/* <FieldSelect
              name="จำนวนแถว"
              value={perPageSelectedOption.value}
              defaultValue={5}
              options={[
                // { value: 1, name: 1 },
                { value: 5, name: 5 },
                { value: 10, name: 10 },
                { value: 50, name: 50 },
                { value: 100, name: 100 },
              ]}
              onChange={(value) => perPageSelectedOption.onChange(value)}
              style={{
                minWidth: `80px`,
                paddingBottom: 0,
              }}
            /> */}
          </Grid>
        )}

        {!helper.IsEmptyFunction(advanceSearchButtonOption.onSearch) && (
          <Grid item md={"auto"} lg={"auto"}>
            <AdvanceSearchButton
              classNames={advanceSearchButtonOption.classNames}
              onSearch={advanceSearchButtonOption.onSearch}
              onReset={advanceSearchButtonOption.onReset}
              children={advanceSearchButtonOption.children}
              styles={advanceSearchButtonOption.styles}
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        borderRadius: "10px",
        backgroundColor: "white",
        padding: "25px",
        boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
        zIndex: 2,
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container rowSpacing={2} justifyContent="center">
        {renderGroupFilter()}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
          justifyContent={"center"}
        >
          {renderGroupBtn()}
        </Grid>
      </Grid>
    </div>
  );
};

export default FilterTab;
