import { Box, Toolbar } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
const MainLayout = () => {
  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      <Header />
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {/* <Toolbar /> */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
