import instance from "../../axiosInstance";
import helper from "utils/helper";
const getAllProductRentalType = async (param) => {
  try {
    return instance.get(`/api/master/product-rental-type`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const createProductRentalType = async (body) => {
  try {
    return instance.post(`/api/master/add-product-rental-type`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const editProductRentalType = async (id, body) => {
  try {
    return instance.patch(`/api/master/edit-product-rental-type/${id}`, body);
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getAllProductRentalType,
  createProductRentalType,
  editProductRentalType,
};
