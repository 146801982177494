import React from "react";
import { Button } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar } from "@mui/material";

const SearchButton = ({ onClick, classNames, styles }) => {
  const handleOnClick = () => {
    onClick();
  };

  return (
    <Avatar
      className={classNames}
      variant="rounded"
      onClick={handleOnClick}
      sx={{
        width: "40px",
        height: "40px",
        filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
        bgcolor: "white",
        ...styles,
      }}
    >
      <SearchIcon sx={{ fontSize: "35px", color: "#121F43" }} />
    </Avatar>
  );
};

export default SearchButton;
