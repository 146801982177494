import { Button } from "antd";
import UpdateIcon from "@mui/icons-material/Update";
import ModalComponent from "components/modal/FormModal";
import React, { useState } from "react";
import { Avatar } from "@mui/material";

const UpdateStatusButton = ({
  classNames = "",
  styles = {},
  onConfirm = () => {},
  title = "",
  children,
  // isModalOpen = false,
  // setIsModalOpen = () => {},
  // disableConfirm = false,
  // setDisableConfirm = () => {},
  onClick = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const handlerOnClick = () => {
    onClick().then((result) => {
      if (result) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    });
  };

  const handlerOnClickX = () => {
    setOpen(false);
  };

  const handlerConfrimUpdate = () => {
    setDisabledBtn(true);
    onConfirm().then((result) => {
      if (result) {
        handlerOnClickX();
      }
      setDisabledBtn(false);
    });
  };

  return (
    <React.Fragment>
      <Avatar
        className={classNames}
        variant="rounded"
        sx={{
          width: "40px",
          height: "40px",
          filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
          bgcolor: "white",
          ...styles,
        }}
        onClick={handlerOnClick}
      >
        <UpdateIcon sx={{ fontSize: "35px", color: "#121F43" }} />
      </Avatar>

      <ModalComponent
        open={open}
        title={title}
        onConfirm={handlerConfrimUpdate}
        onCancel={handlerOnClickX}
        setIsModalOpen={setOpen}
        disableConfirm={disabledBtn}
        setDisableConfirm={setDisabledBtn}
      >
        {children}
      </ModalComponent>
    </React.Fragment>
  );
};

export default UpdateStatusButton;
