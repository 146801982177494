import { TableRows } from "@mui/icons-material";
import { Avatar, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

const SelectRowValue = ({ onSelect, customOptions, width, height }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickRowCount = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseRowCount = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClickRowCount = (value) => {
    console.log("Selected value:", value);
    onSelect(value);
    //   setFilterUser({ ...filterUser, per_page: value });
    setAnchorEl(null);
  };

  const optionsRowCount = [
    { value: 5, name: "5" },
    { value: 10, name: "10" },
    { value: 20, name: "20" },
    { value: 50, name: "50" },
    { value: 100, name: "100" },
  ];
  return (
    <div>
      <Avatar
        variant="rounded"
        sx={{
          width: width ? width : "40px",
          height: height ? height : "40px",
          filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
          //   boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
          bgcolor: "white",
        }}
        onClick={handleClickRowCount}
      >
        <TableRows sx={{ color: "secondary.main", fontSize: 35 }} />
      </Avatar>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseRowCount}
      >
        {customOptions && customOptions.length > 0
          ? customOptions?.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => handleMenuItemClickRowCount(option.value)}
              >
                {option.name}
              </MenuItem>
            ))
          : optionsRowCount?.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => handleMenuItemClickRowCount(option.value)}
              >
                {option.name}
              </MenuItem>
            ))}
      </Menu>
    </div>
  );
};

export default SelectRowValue;
