import {
  Avatar,
  Box,
  Container,
  FormControl,
  Grid,
  Modal,
} from "@mui/material";
import { Button } from "antd";
import ChecklistRtlRoundedIcon from "@mui/icons-material/ChecklistRtlRounded";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useEffect, useState } from "react";
import "../finance.css";
import { subMenuFinance } from "utils/submenuoptions";
import UpdateStatusButton from "components/button/finance/UpdateStatusButton";
import FieldDatePicker from "components/field/FieldDatePicker";
import { createInvoice, getUnpaid } from "utils/api/finance/carrier";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import currency from "currency.js";
import FieldInput from "components/field/FieldInput";
import FieldNumber from "components/field/FieldNumber";
import AdvanceSearchButton from "components/button/AdvanceSearchButton";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import API from "../../../utils/api/work/work";
import SelectRowValue from "components/button/SelectRowValue";
import Loading from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";

const CarrierUnpiad = () => {
  const apiWork = API.create();
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "matching_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "work_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);
  //updatestatus modal
  const [updateStatusForm, setUpdateStatusForm] = useState({
    actual_date: moment().format("YYYY-MM-DD kk:mm"),
    work_code: [],
    carrier_freight_before_tax: 0,
    carrier_freight_tax: 0,
    carrier_insurance_per_trip: 0,
    carrier_penalty: 0,
    carrier_freight_net: 0,
    file: "",
  });
  const [error, setError] = useState({
    actual_date: false,
    work_code: false,
    // file: false,
  });

  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuFinance]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    open: false,
    title: "",
    desc: "",
  });

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setActiveSubMenu(7);
    setSubMenuBreadcrumbs([
      {
        label: "ข้อมูลการเงิน",
        path: null,
      },
      {
        label: "ข้อมูลการเงินผู้ขนส่ง",
        path: null,
      },
      {
        label: "รอตั้งเบิก",
        path: null,
      },
    ]);
    handleSearch();
    getStatus();
  }, []);

  useEffect(() => {
    if (rowSelected.length > 0) {
      const carrier_id = rowSelected[0].carrier_id;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.work_status !== "เสร็จสิ้น") {
            return { ...item, rowDisabled: true };
          } else if (item.carrier_id !== carrier_id) {
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.work_status !== "เสร็จสิ้น") {
            return { ...item, rowDisabled: true };
          } else {
            return { ...item, rowDisabled: false };
          }
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const getStatus = async () => {
    apiWork
      .getWorkStatus()
      .then((response) => {
        console.log(response);
        setWorkStatus(
          response.data.results.map((ele) => {
            return { ...ele, name: ele.name, value: ele.id };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkDuplicate = async () => {
    setLoading(true);
    await apiWork
      .checkDuplicateWork()
      .then((response) => {
        console.log(response);

        const data = response?.data?.data?.map((ele) => {
          return ele?.work?.work_code;
        });

        let text = data?.map((item, index) => {
          return item + `${index === data?.length - 1 ? "" : ", "}`;
        });
        setErrorAlert({
          open: true,
          title:
            data.length > 0
              ? "พบงานที่ผู้ขนส่งรับงานซ้อน"
              : "ไม่พบงานที่ผู้ขนส่งรับงานซ้อน",
          desc: text,
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      work_code: "",
      carrier_name: "",
      project_name: "",
      search: "",
      profile_type:
        formdata.profile_type !== "ALL" ? formdata.profile_type : "",
      payment_type:
        formdata.payment_type !== "ALL" ? formdata.payment_type : "",
      work_status: formdata.work_status !== "ALL" ? formdata.work_status : "",
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getUnpaid(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;

        const resultWithKey = result.map((item) => {
          return { ...item, key: item.work_code };
        });

        const uniqueCarrier_Ids = [
          ...new Set(resultWithKey.map((item) => item.carrier_id)),
        ];

        const updatedResult = resultWithKey.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;
          if (item.work_status !== "เสร็จสิ้น") {
            rowDisabled = true;
          } else {
            if (!resetCheckBox) {
              if (rowSelected.length > 0) {
                const carrierID = rowSelected[0].carrier_id;
                rowIsActive = rowSelected.some(
                  (selectedItem) => selectedItem.key === item.key
                );
                rowDisabled = carrierID !== item.carrier_id;
              }
            }
          }

          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
            key: item.work_code,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueCarrier_Ids.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        error.response.data.description
      );
    }
  };

  const handleUpdatestatusFormChange = (name, event) => {
    if (["actual_date", "file"].includes(name)) {
      const value = event;
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      const value = helper.sanitizeInput(event.target.value);
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleOnclickUpdateStatusButton = async () => {
    if (rowSelected.length > 0) {
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "work_code") {
              if (!result["work_code"]) {
              }
              result["work_code"].push(val["work_code"]);
            } else if (
              [
                "carrier_freight_before_tax",
                "carrier_freight_tax",
                "carrier_insurance_per_trip",
                "carrier_penalty",
                "carrier_freight_net",
              ].includes(key)
            ) {
              result[key] = currency(result[key]).add(val[key]).value;
            }
          });
          return result;
        },
        {
          work_code: [],
          carrier_freight_before_tax: 0,
          carrier_freight_tax: 0,
          carrier_insurance_per_trip: 0,
          carrier_penalty: 0,
          carrier_freight_net: 0,
        }
      );
      const beforetax = helper.formatNumeric(
        groupdata.carrier_freight_before_tax,
        "display"
      );
      const tax = helper.formatNumeric(
        groupdata.carrier_freight_tax,
        "display"
      );
      const net = helper.formatNumeric(
        groupdata.carrier_freight_net,
        "display"
      );
      const insurance = helper.formatNumeric(
        groupdata.carrier_insurance_per_trip,
        "display"
      );
      const penalty = helper.formatNumeric(
        groupdata.carrier_penalty,
        "display"
      );
      setUpdateStatusForm({
        actual_date: moment().format("YYYY-MM-DD kk:mm"),
        work_code: groupdata.work_code,
        carrier_freight_before_tax: beforetax,
        carrier_freight_tax: tax,
        carrier_insurance_per_trip: insurance,
        carrier_penalty: penalty,
        carrier_freight_net: net,
        file: "",
      });
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณาเลือกงานมากกว่า 1 งาน"
      );
      return false;
    }
  };

  const handleSubmitUpdateStatus = async () => {
    const insertData = {
      work_code: updateStatusForm.work_code,
      actual_date: updateStatusForm.actual_date,
      file: updateStatusForm.file,
    };

    const newError = {};
    Object.entries(insertData).forEach(([key, value]) => {
      if (["actual_date", "work_code"].includes(key)) {
        if (
          value === undefined ||
          value === null ||
          value === "" ||
          value === "0.00"
        ) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
      }
    });

    const check_actual_date = moment(
      updateStatusForm.actual_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    newError.actual_date = !check_actual_date;
    setError(newError);

    if (Object.values(newError).every((value) => !value)) {
      try {
        const response = await createInvoice(insertData);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        }
      } catch (error) {
        console.log(error);
        openNotificationWithIcon(
          "error",
          "Notification Error",
          "เกิดข้อผิดพลาดบางอย่าง"
        );
        return false;
      }
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
    }
    return false;
  };

  const handleOnReset = () => {
    setFormSearch({
      ...formSearch,
      profile_type: "ALL",
      payment_type: "ALL",
      work_status: "ALL",
    });
  };

  const handlePagination = (p) => {
    console.log(p);
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.37}
          >
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "matching_date", name: "วันจับคู่งาน" },
                { value: "finish_date", name: "วันที่เสร็จสิ้นงาน" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.37}
          >
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.37}
          >
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "work_code", name: "เลขที่งาน" },
                { value: "project_name", name: "ชื่อโครงการ" },
                { value: "carrier_name", name: "ชื่อโปรไฟล์ผู้ขนส่ง" },
                { value: "work_name", name: "ชื่อประกาศ" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
              closePaddingBottom
            />
          </Grid>
          <Grid
            item
            sx={{ paddingRight: "20px" }}
            xs={12}
            sm={6}
            md={6}
            lg={2.37}
          >
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2.5}
          >
            <SearchButton onClick={handleSearch} />
            <UpdateStatusButton
              title="ตั้งเบิก"
              onClick={handleOnclickUpdateStatusButton}
              onConfirm={handleSubmitUpdateStatus}
            >
              {renderFormUpdateStatus()}
            </UpdateStatusButton>
            <Avatar
              variant="rounded"
              onClick={() => checkDuplicate()}
              sx={{
                width: "40px",
                height: "40px",
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
            >
              <ChecklistRtlRoundedIcon
                sx={{ fontSize: "35px", color: "#121F43" }}
              />
            </Avatar>
            <FormControl>
              <SelectRowValue
                onSelect={(e) => {
                  setPagination({ ...pagination, per_page: e });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
            <AdvanceSearchButton
              onReset={handleOnReset}
              onSearch={handleSearch}
              value={formSearch}
            >
              {renderFormAdvSearch()}
            </AdvanceSearchButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormUpdateStatus = () => {
    return (
      <Box sx={{ width: "360px" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการตั้งเบิก*"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={updateStatusForm.actual_date}
              onChange={(e) => handleUpdatestatusFormChange("actual_date", e)}
              showTime
              error={error.actual_date}
              helperText={error.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมจำนวนงาน"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.work_code.length}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาผู้ขนส่งก่อนหักภาษี"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.carrier_freight_before_tax}
              readOnly
              type="float"
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมภาษีหัก ณ ที่จ่าย"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.carrier_freight_tax}
              readOnly
              type="float"
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมค่าประกันภัยสินค้ารายเที่ยว"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.carrier_insurance_per_trip}
              readOnly
              type="float"
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมค่าปรับ"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.carrier_penalty}
              readOnly
              type="float"
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาผู้ขนส่งสุทธิ"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.carrier_freight_net}
              readOnly
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldUpload
              name={"ใบแจ้งหนี้จากผู้ขนส่ง"}
              icon={<UploadFile />}
              value={updateStatusForm.file}
              accept="image/png, image/jpeg, application/pdf"
              onChange={(e) => handleUpdatestatusFormChange("file", e)}
              // error={error.file}
              textError={error.file ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
        </form>
      </Box>
    );
  };

  const renderFormAdvSearch = () => {
    return (
      <>
        <form>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[
                { value: "matching_date", name: "วันจับคู่งาน" },
                { value: "finish_date", name: "วันที่เสร็จสิ้นงาน" },
              ]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
            />
          </FormControl>
          <FieldDateRangePicker
            className="FieldDateRangePicker-drawer w-100"
            popupClassName="FieldDateRangePicker-drawer-popup"
            name={`วันเริ่มต้น - วันสิ้นสุด`}
            value={{
              start_date: formSearch.dateFrom,
              end_date: formSearch.dateTo,
            }}
            onChange={(e) => handleOnChangeSearch(e, "date")}
            style={{ marginBottom: "23px" }}
          />
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทโปรไฟล์ผู้ขนส่ง`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "บุคคลธรรมดา" },
                { value: "1", name: "นิติบุคคล" },
              ]}
              value={formSearch.profile_type}
              onChange={(e) => handleOnChangeSearch(e, "profile_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ประเภทการชำระเงินงตาม`}
              options={[
                { value: "ALL", name: "ทั้งหมด" },
                { value: "0", name: "เงินสด" },
                { value: "1", name: "เครดิต" },
              ]}
              value={formSearch.payment_type}
              onChange={(e) => handleOnChangeSearch(e, "payment_type")}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`สถานะงาน`}
              options={[{ value: "ALL", name: "ทั้งหมด" }, ...workStatus]}
              value={formSearch.work_status}
              onChange={(e) => handleOnChangeSearch(e, "work_status")}
            />
          </FormControl>
        </form>
      </>
    );
  };

  const renderTable = () => {
    const fields = [
      {
        key: "matching_date",
        label: "วันที่จับคู่งาน",
        render: "date",
        tdclass: "text-center",
      },
      {
        key: "work_code",
        label: "เลขที่งาน",
        tdclass: "text-center",
      },
      {
        key: "work_name",
        label: "ชื่อประกาศ",
      },
      {
        key: "carrier_name",
        label: "ชื่อโปรไฟล์ผู้ขนส่ง",
      },
      {
        key: "carrier_profile_type",
        label: "ประเภทโปรไฟล์",
        tdclass: "text-center",
      },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      {
        key: "carrier_payment_type",
        label: "ประเภทการรับเงินของผู้ขนส่ง",
        tdclass: "text-center",
      },
      {
        key: "work_status",
        label: "สถานะงาน",
      },
      {
        key: "finish_date",
        label: "วันที่เสร็จสิ้นงาน",
        tdclass: "text-center",
        render: "date",
      },
      {
        key: "carrier_freight_before_tax",
        label: "ราคาผู้ขนส่งก่อนหักภาษี",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_freight_tax",
        label: "ภาษีหัก ณ ที่จ่าย",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_insurance_per_trip",
        label: "ประกันภัยสินค้า",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_penalty",
        label: "ค่าปรับ",
        render: "currency",
        tdclass: "text-end ",
      },
      {
        key: "carrier_freight_net",
        label: "ราคาผู้ขนส่งสุทธิ",
        render: "currency",
        tdclass: "text-end ",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={disabledSelectAll}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          type="carrier"
        />
      </>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };
  console.log("errorAlert.desc", errorAlert.desc);
  return (
    <div className="Finance CarrierUnpiad">
      {loading && <Loading />}
      {contextHolder}
      <AlertModal
        open={errorAlert.open}
        title={errorAlert.title}
        description={
          <div style={{ display: "flex", textAlign: "center" }}>
            {errorAlert.desc}
          </div>
        }
        onClose={() => {
          setErrorAlert({
            open: false,
            title: "",
            desc: "",
          });
        }}
      />
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default CarrierUnpiad;
