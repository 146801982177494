import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAdminById } from "utils/api/admin/admin";
import {
  Container,
  Grid,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  Avatar,
  useTheme,
} from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useForm, Controller } from "react-hook-form";
import { Add, Save } from "@mui/icons-material";
import { useCookies } from "react-cookie";

//component in project
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/LoadingBackground";
import { subMenuAdminID } from "utils/submenuoptions";
import helper from "utils/helper";
import FieldSelect from "components/field/FieldSelect";
import FieldUpload from "components/field/FieldUpload";
import FieldPassword from "components/field/FieldPassword";
import { getMenuPermission, editAdmin } from "utils/api/admin/admin";
import AlertModal from "components/modal/AlertModal";
import styles from "./TableAdmin.module.css";
import FieldInput from "components/field/FieldInput";

const DetailAdmin = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState([]);
  const [permissionM, setPermissionM] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [formData, setFormData] = useState(null);
  const [items, setItems] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const breadcrumbs = [
    {
      label: "จัดการผู้ดูแลระบบ",
      path: "",
    },
    {
      label: `${dataDetail?.username}`,
      path: "",
    },
    {
      label: "รายละเอียดผู้ดูและระบบ",
      path: "",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const param = {
      sort_field: "id",
      sort_order: 1,
      page: 1,
      per_page: 100,
    };
    setTimeout(() => {
      getMenuPermission(param)
        .then((res) => {
          if (res.data.code === 1000) {
            const perm = res.data.data.results.map((item) => {
              const { created_at, updated_at, ...rest } = item;
              return rest;
            });
            setPermission(perm);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          throw error;
        });

      getAdminById(id)
        .then((res) => {
          if (res.data.code === 1000) {
            setDataDetail({
              ...res.data.data,
              admin_role_type: res.data.data.admin_role_type.id,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        });
    }, 1000);
  }, [id]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();

  useEffect(() => {
    console.log("dataDetail", dataDetail);
    console.log("permission", permission);
    // IntitailPermission();
  }, [dataDetail, permission]);

  useEffect(() => {
    // IntitailPermission();
    if (dataDetail && permission) {
      const mergePermission = permission.map((itemPerm) => {
        const matchData = dataDetail?.adminPermissions.find(
          (itemData) => itemPerm.id === itemData.menu_permission_id
        );
        if (matchData) {
          return { ...matchData, ...itemPerm };
        }
        return itemPerm;
      });
      console.log("mergePermission", mergePermission);
      setItems(
        ...items,
        mergePermission?.map((res) => ({
          menuPermissionId: res.id,
          name: res.name,
          visit: res.visit ?? false,
          create: res.create ?? false,
          deleteP: res.delete ?? false,
          edit: res.edit ?? false,
          print: res.print ?? false,
          exportP: res.export ?? false,
          all:
            (res.visit &&
              res.create &&
              res.delete &&
              res.edit &&
              res.print &&
              res.export) ??
            false,
        }))
      );

      // setPermissionM(mergePermission);
    }
  }, [dataDetail, permission]);

  useEffect(() => {
    if (dataDetail) {
      // Store initial values
      const newInitialFormValues = {};
      Object.keys(dataDetail).forEach((fieldName) => {
        if (Array.isArray(dataDetail[fieldName])) {
          newInitialFormValues["adminPermission"] = dataDetail[fieldName].map(
            (perm) => ({
              menuPermissionId: perm.menu_permission_id,
              visit: perm.visit,
              create: perm.create,
              delete: perm.delete,
              edit: perm.edit,
              print: perm.print,
              export: perm.export,
            })
          );
        } else {
          newInitialFormValues[fieldName] = dataDetail[fieldName];
        }
        if (fieldName !== "password") {
          setValue(fieldName, dataDetail[fieldName]); // Set initial values
        }
      });
      setInitialFormValues(newInitialFormValues);
    }
  }, [dataDetail, setValue]);

  useEffect(() => {
    const modifiedItems = items?.map(
      ({
        deleteP,
        exportP,
        create,
        edit,
        print,
        menuPermissionId,
        visit,
        name,
        all,
      }) => ({
        menuPermissionId: menuPermissionId,
        visit: visit,
        create: create,
        delete: deleteP,
        edit: edit,
        print: print,
        export: exportP,
      })
    );

    // Filter out items where all properties are false
    const filteredItems = modifiedItems.filter((item) => {
      return Object.keys(item).some(
        (key) => key !== "menuPermissionId" && item[key]
      );
    });

    if (filteredItems) {
      setValue("adminPermission", filteredItems);
    }
  }, [items]);

  // useEffect(() => {
  //   console.log("items", items);
  //   console.log("initialFormValues", initialFormValues);
  // }, [items, initialFormValues]);

  const handleForm = (key, val) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const handleCheckboxClick = (itemId, property) => {
    console.log("items", items);
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.menuPermissionId === itemId) {
          const updatedItem = {
            ...item,
            [property]: !item[property],
          };

          if (property === "all") {
            const allChecked = updatedItem.all;
            updatedItem.visit = allChecked;
            updatedItem.create = allChecked;
            updatedItem.deleteP = allChecked;
            updatedItem.edit = allChecked;
            updatedItem.print = allChecked;
            updatedItem.exportP = allChecked;
          } else {
            updatedItem.all =
              updatedItem.visit &&
              updatedItem.create &&
              updatedItem.deleteP &&
              updatedItem.edit &&
              updatedItem.print &&
              updatedItem.exportP;
          }

          return updatedItem;
        } else {
          return item;
        }
      })
    );
  };

  const handleMaxDigit = (event, field, max) => {
    const newValue = event.target.value;
    // Limit input to a maximum of 10 characters
    if (newValue.length <= max) {
      setValue(field, newValue);
    }
    if (newValue.length > 0) {
      clearErrors(field);
    }
  };
  //setValue Whenitem change

  const onSubmit = async (data) => {
    setLoading(true);
    console.log("data onSubmit admin", data);
    const changedData = {};
    if (
      Array.isArray(data.adminPermission) &&
      JSON.stringify(data.adminPermission) !==
        JSON.stringify(initialFormValues.adminPermission)
    ) {
      changedData.adminPermission = data.adminPermission;
    }

    // Check other fields for changes
    Object.keys(data).forEach((fieldName) => {
      if (
        fieldName !== "adminPermission" &&
        fieldName !== "adminPermissions" &&
        data[fieldName] !== initialFormValues[fieldName]
      ) {
        changedData[fieldName] = data[fieldName];
      } else if (data[fieldName] === initialFormValues[fieldName]) {
        changedData[fieldName] = "";
      }
    });
    console.log("changedData", changedData);

    const GForm = new FormData();
    Object.keys(changedData).forEach((key) => {
      if (key === "adminPermission") {
        GForm.append(key, JSON.stringify(changedData[key]));
      } else if (changedData[key] === undefined || changedData[key] === "") {
        delete GForm.delete(key);
      } else {
        GForm.append(key, changedData[key]);
      }
    });
    delete GForm.delete("id");
    delete GForm.delete("adminPermissions");
    delete GForm.delete("createdAt");
    delete GForm.delete("updatedAt");
    delete GForm.delete("confirmPassword");

    for (const [key, value] of GForm.entries()) {
      console.log(`${key}: ${value}`);
    }
    if (id) {
      await editAdmin(id, GForm)
        .then((response) => {
          console.log("response", response);
          if (response.data.code === 1000) {
            setLoading(false);
            setAlertModal(true);
            console.log("edit Success");
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              setErrorAlert({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window.location.reload();
            }
          }
        });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={alertModal}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setAlertModal(false);
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
          navigate(-1);
        }}
      />
      <Container>
        <SubMenu
          menus={subMenuAdminID}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        />
        {dataDetail ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // width: "100vh",
              height: "100%",
              overflow: "auto",
              margin: 0,
            }}
          >
            {/* <Grid xs={12} item> */}
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "20px",
                width: "400px",
                minWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px 25px 10px 25px",
                height: "80vh",
                overflow: "auto",
              }}
            >
              <Typography fontSize={"20px"} fontWeight={500}>
                {dataDetail?.username}
              </Typography>
              <Typography
                fontSize={"14px"}
                fontWeight={300}
                fontStyle={"italic"}
                color={"grey.main"}
              >
                วันที่สร้างรายการ{" "}
                {helper.ToConvertDateTimeForTable(dataDetail?.createdAt)}
              </Typography>
              <Typography
                fontSize={"14px"}
                fontWeight={300}
                fontStyle={"italic"}
                color={"grey.main"}
              >
                วันที่แก้ไขข้อมูลล่าสุด{" "}
                {helper.ToConvertDateTimeForTable(dataDetail?.updatedAt)}
              </Typography>

              <form
                style={{ display: "flex", width: "100%" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div style={{ gap: "15px", width: "100%" }}>
                  <Controller
                    name="active"
                    defaultValue={dataDetail?.active}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldSelect
                        options={[
                          { value: true, name: "ใช้งาน" },
                          { value: false, name: "ไม่ได้ใช้งาน" },
                        ]}
                        name={"สถานะใช้งาน*"}
                        value={value}
                        onChange={onChange}
                        style={{
                          width: "350px",
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="image"
                    // defaultValue={dataDetail?.image}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldUpload
                        name={"รูปโปรไฟล์*"}
                        accept="image/jpeg,image/png"
                        icon={<UploadFile />}
                        value={value} //คลิกเพื่อเปิด
                        onChange={onChange}
                        // url={dataDetail.image}
                        style={{
                          width: "-webkit-fill-available",
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="username"
                    defaultValue={dataDetail?.username}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldInput
                        id="username"
                        label="ชื่อผู้ใช้งาน*"
                        onChange={onChange}
                        value={value}
                        style={{ width: "-webkit-fill-available" }}
                        readOnly={true}
                      />
                    )}
                  />

                  <Controller
                    name="admin_role_type"
                    defaultValue={dataDetail.admin_role_type}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldSelect
                        options={[
                          { value: 0, name: "SuperAdmin" },
                          { value: 1, name: "ฝ่ายขาย" },
                          { value: 2, name: "ฝ่ายบริการลูกค้า" },
                          { value: 3, name: "ฝ่ายปฏิบัติการ" },
                          { value: 4, name: "ฝ่ายการเงินและบัญชี" },
                          { value: 5, name: "ฝ่ายเทคโนโลยีสารสนเทศ" },
                        ]}
                        name={"บทบาทผู้ดูแลระบบ*"}
                        onChange={onChange}
                        value={value}
                        style={{
                          width: "-webkit-fill-available",
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="firstname"
                    defaultValue={dataDetail.firstname}
                    control={control}
                    rules={{ required: "กรุณากรอกชื่อ" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldInput
                        id="firstname"
                        label="ชื่อ*"
                        onChange={onChange}
                        value={value}
                        error={!!errors.firstname}
                        helperText={
                          errors.firstname ? errors.firstname.message : ""
                        }
                        style={{ width: "-webkit-fill-available" }}
                      />
                    )}
                  />
                  <Controller
                    name="lastname"
                    defaultValue={dataDetail.lastname}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldInput
                        id="lastname"
                        label="นามสกุล*"
                        onChange={onChange}
                        value={value}
                        style={{ width: "-webkit-fill-available" }}
                      />
                    )}
                  />

                  <Controller
                    name="phone"
                    defaultValue={dataDetail.phone}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldInput
                        id="phone"
                        label="เบอร์มือถือ*"
                        value={value}
                        onChange={(e) => {
                          handleMaxDigit(e, "phone", 10);
                        }}
                        style={{ width: "-webkit-fill-available" }}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    defaultValue={dataDetail.email}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldInput
                        id="email"
                        label="อีเมล*"
                        value={value}
                        onChange={onChange}
                        style={{ width: "-webkit-fill-available" }}
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      minLength: {
                        value: 6,
                        message:
                          "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
                      },
                      pattern: {
                        value:
                          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!_\-]+$/,
                        message:
                          "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldPassword
                        id="password"
                        name="เปลี่ยนรหัสผ่าน"
                        value={value}
                        style={{ width: "-webkit-fill-available" }}
                        onChange={onChange}
                        // error={!!errors.password}
                        // textError={errors.password ? errors.password.message : ""}
                      />
                    )}
                  />
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      minLength: {
                        value: 6,
                        message:
                          "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
                      },
                      pattern: {
                        value:
                          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!_\-]+$/,
                        message:
                          "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
                      },
                      validate: (value) =>
                        value === getValues().password || "รหัสผ่านไม่ตรงกัน",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldPassword
                        id="confirmPassword"
                        name="ยืนยันรหัสผ่าน"
                        value={value}
                        style={{ width: "-webkit-fill-available" }}
                        onChange={onChange}
                        error={!!errors.confirmPassword}
                        textError={
                          errors.confirmPassword
                            ? errors.confirmPassword.message
                            : ""
                        }
                      />
                    )}
                  />

                  <Controller
                    name="signature_image"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FieldUpload
                        name={"ลายเซ็น"}
                        accept="image/jpeg,image/png"
                        icon={<UploadFile />}
                        value={value}
                        onChange={onChange}
                        style={{
                          width: "-webkit-fill-available",
                        }}
                      />
                    )}
                  />
                </div>

                {/* <button type="submit">hhhhhhhh</button> */}
                <Avatar
                  alt="Avatar Save"
                  sx={{
                    position: "fixed",
                    bottom: "35px",
                    right: "97px",
                    width: 100,
                    height: 100,
                    bgcolor: "secondary.main",
                    cursor: "pointer",
                    zIndex: 200,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  <Save sx={{ color: "white", fontSize: "70px" }} />
                </Avatar>
              </form>
            </div>
            {/* </Grid> */}
            {/* <Grid xs={12} item> */}
            <div style={{ paddingRight: "20px" }} />
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                height: "80vh",
                width: "928px",
                overflow: "auto",
              }}
            >
              <TableContainer>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                    },
                  }}
                  aria-label="simple table"
                >
                  <TableHead className={styles.tableHeadDetail}>
                    <TableRow>
                      <TableCell
                        className={styles.headPermission}
                        align="center"
                      >
                        สิทธิ์การใช้งาน
                      </TableCell>
                      <TableCell
                        className={styles.headPermission}
                        align="center"
                      >
                        ทั้งหมด
                      </TableCell>
                      <TableCell
                        className={styles.headPermission}
                        align="center"
                      >
                        เข้าชม
                      </TableCell>
                      <TableCell
                        className={styles.headPermission}
                        align="center"
                      >
                        เพิ่ม
                      </TableCell>
                      <TableCell
                        className={styles.headPermission}
                        align="center"
                      >
                        ลบ
                      </TableCell>
                      <TableCell
                        className={styles.headPermission}
                        align="center"
                      >
                        แก้ไข
                      </TableCell>
                      <TableCell
                        className={styles.headPermission}
                        align="center"
                      >
                        พิมพ์
                      </TableCell>
                      <TableCell
                        className={styles.headPermission}
                        align="center"
                      >
                        ส่งออก
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items &&
                      items.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell
                            className={styles.listPermission}
                            component="th"
                            scope="row"
                            sx={{ padding: 0 }}
                          >
                            {item.name}
                          </TableCell>
                          <TableCell sx={{ padding: 0 }} align="right">
                            <Checkbox
                              {...label}
                              name="all"
                              checked={item.all}
                              onClick={() =>
                                handleCheckboxClick(
                                  item.menuPermissionId,
                                  "all"
                                )
                              }
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 0 }} align="right">
                            <Checkbox
                              {...label}
                              name="visit"
                              checked={item.visit}
                              onClick={() =>
                                handleCheckboxClick(
                                  item.menuPermissionId,
                                  "visit"
                                )
                              }
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 0 }} align="right">
                            <Checkbox
                              name="create"
                              checked={item.create}
                              onClick={() =>
                                handleCheckboxClick(
                                  item.menuPermissionId,
                                  "create"
                                )
                              }
                              {...label}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 0 }} align="right">
                            <Checkbox
                              name="delete"
                              checked={item.deleteP}
                              onClick={() =>
                                handleCheckboxClick(
                                  item.menuPermissionId,
                                  "deleteP"
                                )
                              }
                              {...label}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 0 }} align="right">
                            <Checkbox
                              name="edit"
                              checked={item.edit}
                              onClick={() =>
                                handleCheckboxClick(
                                  item.menuPermissionId,
                                  "edit"
                                )
                              }
                              {...label}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 0 }} align="right">
                            <Checkbox
                              name="print"
                              checked={item.print}
                              onClick={() =>
                                handleCheckboxClick(
                                  item.menuPermissionId,
                                  "print"
                                )
                              }
                              {...label}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: 0 }} align="right">
                            <Checkbox
                              name="export"
                              checked={item.exportP}
                              onClick={() =>
                                handleCheckboxClick(
                                  item.menuPermissionId,
                                  "exportP"
                                )
                              }
                              {...label}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {/* </Grid> */}
          </div>
        ) : (
          ""
        )}
      </Container>
    </React.Fragment>
  );
};

export default DetailAdmin;
