import React, { useState } from "react";

import { Pagination, Table } from "antd";
import "./TableAntdCustom.css";
import { useNavigate } from "react-router-dom";
import helper from "utils/helper";
import PaginationComponent from "components/pagination/Pagination";

const TableAntdCustom = ({
  mainColunmns = [],
  expandColumns = [],
  expandable = false,
  selectable = false,
  mainData = [],
  expandData = [],
  scroll = {},
  setSelectedRows = () => {},
  pagination = {
    page: 1,
    per_page: 10,
    total: 100,
  },
  setPagination = () => {},
  loading = false,
  onChange = () => {},
  handlePageChange = () => {},
  href = ``,
  onClickRow = () => {},
  rowSelectionType = "checkbox", // checkbox, radio
  nameSubDataArray = "",
  closeCounter = false,
}) => {
  const navigate = useNavigate();
  const [countSelectedRows, setCountSelectedRows] = useState(0);
  console.log("countSelectedRows", countSelectedRows);
  const rowSelection = {
    type: rowSelectionType,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      onChange(selectedRowKeys, selectedRows);
      setSelectedRows({ selectedRowKeys, selectedRows });
      setCountSelectedRows(selectedRows.length);
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setCountSelectedRows(selectedRows.length);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setCountSelectedRows(selectedRows.length);
    },
  };

  const [checkStrictly, setCheckStrictly] = useState(false);
  const expandedRowRender = (data) => {
    console.log(data);
    return (
      <Table
        className="headerExpandedTable"
        columns={expandColumns}
        dataSource={data[nameSubDataArray]}
        pagination={false}
        rowKey="id"
      />
    );
  };
  const columns = [Table.EXPAND_COLUMN, ...mainColunmns];

  return (
    <>
      <div>
        <Table
          className="headerOutsideTable"
          scroll={scroll}
          sticky={true}
          columns={columns}
          rowSelection={
            selectable
              ? {
                  ...rowSelection,
                }
              : null
          }
          // rowExpandable={(record) => record.active}
          expandable={
            expandable
              ? {
                  expandedRowRender,
                }
              : null
          }
          dataSource={mainData}
          rowKey="id"
          // pagination={{
          //   ...pagination,
          //   showTotal: (total) => `Total ${total} items`,
          // }}
          pagination={false}
          loading={loading}
          onChange={onChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                href !== ""
                  ? navigate(href + record.id)
                  : onClickRow(record.id);
              },
            };
          }}
        />
      </div>
      {!closeCounter && (
        <div style={{ justifyContent: "left" }}>
          แสดงผล{" "}
          {pagination.page !== 1
            ? pagination.per_page * pagination.page + 1 - pagination.per_page
            : 1}
          -
          {pagination.page !== 1
            ? parseInt(pagination.per_page) * parseInt(pagination.page) +
                parseInt(pagination.per_page) -
                parseInt(pagination.per_page) >
              parseInt(pagination.total)
              ? pagination.total
              : parseInt(pagination.per_page) * parseInt(pagination.page) +
                parseInt(pagination.per_page) -
                parseInt(pagination.per_page)
            : parseInt(pagination.per_page) > parseInt(pagination.total)
            ? parseInt(pagination.total)
            : parseInt(pagination.per_page)}{" "}
          จาก {pagination.total}{" "}
          {countSelectedRows !== 0
            ? `(เลือกแล้ว ${countSelectedRows} รายการ)`
            : ""}
        </div>
      )}
      {/* <div style={{ flex: 1, textAlign: "center" }}>
        <Pagination
          page={pagination.page}
          total={pagination.total}
          per_page={pagination.per_page}
          onChange={handlePageChange}
        />
      </div> */}

      {!helper.IsEmptyFunction(setPagination) && (
        <PaginationComponent
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
        />
      )}
    </>
  );
};
export default TableAntdCustom;
