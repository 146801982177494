import axios from "axios";
import Swal from "sweetalert2";
import ENV from "./environment";
import { longdo } from "components/map/LongdoMap";
import { getAllSubDistrict, getSubDistrict } from "./api/masterData/gis";
import helper from "./helper";

const google = window.google;
const HelperMap = {
  getGoogleMaps() {
    // If we haven't already defined the promise, define it
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve) => {
        // Add a global handler for when the API finishes loading
        window.resolveGoogleMapsPromise = () => {
          // Resolve the promise
          resolve(google);

          // Tidy up
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&callback=resolveGoogleMapsPromise&language=th&libraries=places,geometry`;
        script.async = true;
        document.body.appendChild(script);
      });
    }

    // Return a promise for the Google Maps API
    return this.googleMapsPromise;
  },
  async getDetailLocation(data, latlng = null) {
    console.log("helper", data);
    if (data && data.address_components) {
      let origin = data.address_components;
      let postal_code = origin.filter((data) => {
        if (data.types.indexOf("postal_code") >= 0) {
          return data;
        }
      });

      let administrative_area_level_1 = origin.filter((data) => {
        // province
        if (data.types.indexOf("administrative_area_level_1") >= 0) {
          return data;
        }
      });

      let administrative_area_level_2 = origin.filter((data) => {
        // amphur
        if (data.types.indexOf("administrative_area_level_2") >= 0) {
          return data;
        }
      });

      let locality = origin.filter((data) => {
        // tumbon
        if (
          (data.types.indexOf("locality") >= 0 ||
            data.types.indexOf("sublocality") >= 0) &&
          data.types.indexOf("polocality") <= 0
        ) {
          return data;
        }
      });

      let sublocality_level_1 = origin.filter((data) => {
        // เขต
        if (data.types.indexOf("sublocality_level_1") >= 0) {
          return data;
        }
      });

      let sublocality_level_2 = origin.filter((data) => {
        // แขวง
        if (data.types.indexOf("sublocality_level_2") >= 0) {
          return data;
        }
      });

      let route = origin.filter((data) => {
        // ถนน
        if (data.types.indexOf("route") >= 0) {
          return data;
        }
      });

      let postal = postal_code.length > 0 ? postal_code[0].long_name : "";
      let area_level_1 =
        administrative_area_level_1.length > 0
          ? administrative_area_level_1[0].long_name
          : "";
      let area_level_2 =
        administrative_area_level_2.length > 0
          ? administrative_area_level_2[0].long_name
          : "";
      let area_locality = locality.length > 0 ? locality[0].long_name : "";
      let sublocality1 =
        sublocality_level_1.length > 0 ? sublocality_level_1[0].long_name : "";
      let sublocality2 =
        sublocality_level_2.length > 0 ? sublocality_level_2[0].long_name : "";
      let area_route = route.length > 0 ? route[0].long_name : "";

      let destination_route = area_route !== "" ? area_route : "";
      let destination_tumbon =
        area_locality !== "" ? area_locality : sublocality2;
      let destination_amphur =
        area_level_2 !== "" ? area_level_2 : sublocality1;
      let destination_province =
        area_level_1 === "" ? data[0].long_name : area_level_1;
      let destination_zip = postal;
      console.log("locality", locality);
      console.log("area_locality", area_locality);

      if (!destination_zip || destination_zip === "") {
        let subdis = await getAllSubDistrict({ search: destination_tumbon });
        if (subdis.data.data.results.length > 0) {
          destination_zip = subdis.data.data.results[0].postal_code;
        }
      }
      return {
        address: data.formatted_address.replaceAll(",", ""),
        province: destination_province,
        district: destination_amphur,
        subdistrict: destination_tumbon,
        postal_code: destination_zip,
        lat: latlng ? latlng.lat : data.geometry.location.lat(),
        long: latlng ? latlng.lng : data.geometry.location.lng(),
      };
    }

    // body.destination_province = destination_province;
    // body.destination_amphur = destination_amphur;
    // body.destination_tumbon = destination_tumbon;
    // body.destination_zip = destination_zip;
    // body.destination_address = data.formatted_address;
    // body.destination_lat = data.geometry.location.lat;
    // body.destination_lon = data.geometry.location.lng;
    // return body;
  },
  async getAddressFromLocation(location) {
    console.log(location);

    let data;
    await axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?language=th&latlng=" +
          location.lat +
          "," +
          location.lon +
          "&key=" +
          ENV.GOOGLE_KEY,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response, 'searchLoadlong');
        if (response?.data?.results) {
          let tmp = response.data.results.filter(
            (e) =>
              e.geometry.location.lat === parseFloat(location.lat) &&
              e.geometry.location.lng === parseFloat(location.lon)
          );
          console.log(tmp);
          if (tmp.length > 0) {
            // setList(tmp);
            data = tmp[0];
          } else {
            // setList(response?.data?.results);
            data = response.data.results[0];
          }
        }
      });
    return data;
  },
  async getDistance(data) {
    // console.log(data);
    let returnData = {
      allInterval: "",
      allDistance: "",
      orderDistance: "",
      orderInterval: "",
    };
    let allDistance = returnData.allDistance;
    let allInterval = returnData.allInterval;
    for (let [index, e] of data.entries()) {
      if (index !== 0) {
        let positionData = {
          key: ENV.MAP_KEY[ENV.ENV].KEY_1,
          flon: data[index - 1].long,
          flat: data[index - 1].lat,
          tlon: e.long,
          tlat: e.lat,
          mode: "c",
          type: 1,
        };
        let link =
          "https://api.longdo.com/RouteService/geojson/route?&key=" +
          ENV.MAP_KEY[ENV.ENV].KEY_1 +
          "&flon=" +
          data[index - 1].long +
          "&flat=" +
          data[index - 1].lat +
          "&tlon=" +
          e.long +
          "&tlat=" +
          e.lat +
          "&mode=c&type=1";
        // console.log(
        //   "https://api.longdo.com/RouteService/geojson/route?&key=c78c11c6f1148e1102a79aa1975f3808&flon=102.0523853&flat=14.9036788&tlon=102.0599344&tlat=14.9022394&mode=c&type=1"
        // );
        // console.log();
        // return;
        await axios
          .get(link, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("getDistance", response);
            // await calDistance(positionData);
            // allDistance = "";
            // allInterval = "";
            let order_interval;
            let order_distance;
            // const { data } = response;
            // console.log(response, "fasdfasfas");
            if (allDistance === "" || allInterval === "") {
              allDistance = parseFloat(response.data.data.distance / 1000);
              //จากวินาทีเป็นนาที
              allInterval = parseFloat(response.data.data.interval / 60);
            } else {
              allDistance =
                parseFloat(allDistance) +
                parseFloat(response.data.data.distance / 1000);
              // เวลาตำแน่งใหม่บวกกัน จากวินาทีเป็นนาที
              allInterval =
                parseFloat(allInterval) +
                parseFloat(response.data.data.interval / 60);
            }
            order_distance = allDistance.toFixed(2);
            if (allInterval.toFixed(2) > 60) {
              // จากนาทีเป็น ชม
              let interval = parseFloat(allInterval / 60);
              // job.orderDetail.order_interval =
              //     interval.toFixed(2) + " ชม.";
              let string = interval.toFixed(2) + "";
              let time = string.split(".");
              if (time.length === 2) {
                if (time[1] > 60) {
                  let int = parseInt(time[1]);
                  let min = parseFloat(int / 60);
                  let intH = parseInt(time[0]);
                  let hours = parseFloat(intH + min);
                  let hToS = hours.toFixed(2).toString();
                  let showTime = hToS.split(".");
                  if (showTime[1] === 60) {
                    order_interval = parseInt(showTime[0]) + 1 + " ชม. ";
                  } else {
                    order_interval =
                      showTime[0] + " ชม. " + showTime[1] + " นาที";
                  }
                } else {
                  // console.log(
                  //     "testt",
                  //     time[0] + " ชม. " + time[1] + " นาที"
                  // );
                  if (time[1] === 60) {
                    order_interval = parseInt(time[0]) + 1 + " ชม. ";
                  } else {
                    order_interval = time[0] + " ชม. " + time[1] + " นาที";
                  }
                }
              }
              // else {
              //     console.log("test", time);
              //     job.orderDetail.order_interval =
              //         time[0] + " ชม. " + time[1] + " นาที";
              // }
            } else {
              if (allInterval % 1 !== 0) {
                order_interval =
                  Math.floor(allInterval) +
                  " นาที " +
                  (60 * (allInterval % 1)).toFixed(0) +
                  " วินาที";
              } else {
                order_interval = allInterval.toFixed(0) + " นาที";
              }
            }
            // console.log('asdfasdfasdf');
            // console.log(order_interval, order_distance, 'fasdfasdfasdf');
            returnData = {
              allInterval: allInterval,
              allDistance: allDistance,
              orderDistance: order_distance,
              orderInterval: order_interval,
            };
            // if (index === data.length - 1) {
            //   console.log(returnData, 'returnData');
            //   return returnData;
            // }
            // console.log(returnData);
            // return returnData;
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
      // console.log(index, e);
    }
    console.log(returnData);
    return returnData;
  },
  async googlePlusCode(val) {
    let isLatLong = val.split(",");

    await axios
      .get(
        "https://maps.googleapis.com/maps/api/place/textsearch/json?query=" +
          val +
          "&language=th&key=" +
          ENV.GOOGLE_KEY,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response, "pluscode");

        if (response.data.results.length > 0) {
          let text =
            response.data.results[0].geometry.location.lat +
            "," +
            response.data.results[0].geometry.location.lng;
          console.log(isLatLong);
          let place_id = response.data.results[0].place_id;
          let name = response.data.results[0].name;
          this.googleAutoComplete(text, place_id, name);
        }
      });
  },
  async googleAutoComplete(val, place_id = null, name = null) {
    let isLatLong = val.split(",");

    console.log("googleAutoComplete", val);
    let detail;
    await axios
      .get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          val +
          "&language=th&key=" +
          ENV.GOOGLE_KEY,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // setListAutoComplete(response.data.predictions);
        // setShowList(true);
        if (response?.data?.results) {
          let tmp = response.data.results.filter(
            (e) =>
              (e.geometry.location.lat === parseFloat(isLatLong[0]) &&
                e.geometry.location.lng === parseFloat(isLatLong[1])) ||
              (place_id && place_id === e.place_id)
          );
          console.log(tmp);
          if (tmp.length > 0) {
            tmp[0].name = name ?? null;
            // setPlaceList(tmp);
            detail = tmp;
          } else {
            // setPlaceList(response?.data?.results);
            detail = response?.data?.results;
          }
        }
        // setList(response.data.plus_code)
        console.log(response);
      });
    return detail;
  },
  async getGeocode(location) {
    let data;
    await this.getGoogleMaps().then(async () => {
      const googleGeoCoder = await new window.google.maps.Geocoder();
      await googleGeoCoder.geocode(
        {
          location: {
            lat: parseFloat(location.lat),
            lng: parseFloat(location.lng),
          },
        },
        function (results, status) {
          if (status === "OK") {
            //   map.setCenter(results[0].geometry.location);
            // console.log(results[0]);

            data = results[0];
          } else {
            alert(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    });
    return data;
  },
  async renderCircle(radius, location, state) {
    let radian = parseInt(radius) / 100;

    const circle = new longdo.Circle(location, radian, {
      lineWidth: 1,
      lineColor: state === "origin" ? "#488aff" : "#f00",
      fillColor:
        state === "origin" ? "rgb(72,138,255,0.4)" : "rgb(255,0,0,0.4)",
      weight: "longdo.OverlayWeight.Top",
    });
    return circle;
  },
  getBound(data, boundData) {
    // console.log(boundData);
    // return;
    let bound = boundData;
    if (bound.maxLon === null || bound.maxLon < parseFloat(data.long)) {
      bound.maxLon = parseFloat(data.long);
    }
    if (bound.maxLat === null || bound.maxLat < parseFloat(data.lat)) {
      bound.maxLat = parseFloat(data.lat);
    }
    if (bound.minLon === null || bound.minLon > parseFloat(data.long)) {
      bound.minLon = parseFloat(data.long);
    }
    if (bound.minLat === null || bound.minLat > parseFloat(data.lat)) {
      bound.minLat = parseFloat(data.lat);
    }
    return bound;
  },
  async getMarkerFromStatus(status) {
    console.log("status", status);
    let mark = [];
    for (const { ele, index } of status.map((ele, index) => ({
      ele,
      index,
    }))) {
      if (ele.workTrackingDetail) {
        console.log(ele);
        if (
          ele.workTrackingDetail.epod_lat &&
          ele.workTrackingDetail.epod_long
        ) {
          // this
          let marker = await this.renderMaker({
            lat: ele.workTrackingDetail.epod_lat,
            long: ele.workTrackingDetail.epod_long,
            type: ele.work_status === 8 ? "pickup" : "send",
          });
          // console.log("marker", marker);
          mark.push(marker);
        }
      }
      if (ele.workAbnormal) {
        if (ele.workAbnormal.abnormal_lat && ele.workAbnormal.abnormal_long) {
          // this
          let marker = await this.renderMaker({
            lat: ele.workAbnormal.abnormal_lat,
            long: ele.workAbnormal.abnormal_long,
            type: "abnormal",
          });
          // console.log("marker", marker);
          mark.push(marker);
        }
      }
    }

    return mark;
  },
  async renderMaker(detail) {
    let data = detail;
    let html = "";
    let location = {
      lat: data.lat,
      lon: data.long,
    };
    console.log("data renderMaker", data);
    if (data.type === "pickup") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("../assets/image/work/pick.png") +
        '" style="width: 30px;margin-top: 0px;"/></div>"';
    } else if (data.type === "send") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("../assets/image/work/send.png") +
        '" style="width: 30px;margin-top: 0px;"/>"';
    } else if (data.type === "driver") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("../assets/image/work/truckpin_new.png") +
        '" style="width: 50px;margin-top: 0px;"/>"';
    } else {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("../assets/image/work/abnormal.png") +
        '" style="width: 30px;margin-top: 0px;"/>"';
    }
    let mark = await new window.longdo.Marker(location, {
      title:
        data.type === "pickup"
          ? "จุดรับ"
          : data.type === "send"
          ? "จุดส่ง"
          : "แจ้งปัญหา",
      draggable: false,
      clickable: false,
      weight: window.longdo.OverlayWeight.Top,
      icon: {
        html,
      },
      popup:
        data.type === "driver" && data.status >= 6 && data.status <= 10
          ? {
              // detail: helper.momentDateTime(data.date),
              html: `<div
                  style="
                    background: #fff;
                    padding: 10px;
                    border-radius: 10px;
                    white-space: nowrap;
                    position: relative;
                    top: -50px;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                  ">
                    ${helper.momentDateTime(data.date)}
                    <div
                      style="
                        position: absolute;
                        bottom: -10px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 0;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 10px solid #fff;
                      ">
                    </div>
                 </div>`,
            }
          : false,
    });
    return mark;
  },
  async getPlace(search) {
    let detail = [];
    await axios
      .get(
        "https://search.longdo.com/mapsearch/json/search?keyword=" +
          search +
          "&key=" +
          ENV.MAP_KEY[ENV.ENV].KEY_1 +
          "&version=2&callback=longdo.callback.search4",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log("getPlace", response);
        let place = response.data
          .replace("longdo.callback.search4(", "")
          .slice(0, -1);
        detail = JSON.parse(place).data[0];
      })
      .catch(() => {
        // console.log('error')
      });
    return detail;
  },
  async getSuggest(search) {
    let detail = [];
    await axios
      .get(
        "https://search.longdo.com/mapsearch/json/suggest?keyword=" +
          search +
          "&local=th&version=2&callback=longdo.callback.suggest4&key=" +
          ENV.MAP_KEY[ENV.ENV].KEY_1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log("getPlace", response.data);
        let place = response.data
          .replace("longdo.callback.suggest4(", "")
          .slice(0, -1);
        console.log("place");

        detail = JSON.parse(place).data;
      })
      .catch(() => {
        // console.log('error')
      });
    return detail;
  },
  async getPlaceFormLatLong(location) {
    let detail = [];
    await axios
      .get(
        "https://api.longdo.com/map/services/address?lat=" +
          location.lat.toString().replace(" ", "") +
          "&nowater=true&noaoi=true&noroad=true&noelevation=true&lon=" +
          location.lon.toString().replace(" ", "") +
          "&key=" +
          ENV.MAP_KEY[ENV.ENV].KEY_1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("getPlaceFormLatLong", response);
        detail = response.data;
      })
      .catch(() => {
        // console.log('error')
      });
    return detail;
  },

  async ApproximateTime(_distance, _speed) {
    console.log("ApproximateTime", _distance, _speed);
    const time = Number(_distance) / Number(_speed);
    let calculateTime = time;
    console.log("calculateTime", calculateTime);
    let day = 0;
    let hour = 0;
    let minute = 0;
    while (calculateTime > 0) {
      if (calculateTime >= 8) {
        day += 1;
        calculateTime -= 8;
      } else {
        hour = Math.floor(calculateTime);
        minute = Math.round((calculateTime - hour) * 60);
        calculateTime = 0; // End loop
      }
    }
    return `${day !== 0 ? `${day} วัน, ` : ""} ${
      hour !== 0 ? `${hour} ชั่วโมง, ` : ""
    } ${minute !== 0 ? `${minute} นาที` : ""}`;
  },
};
export default HelperMap;
