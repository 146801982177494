import instance from "../../axiosInstance";

const getAllTenant = async (param) => {
  try {
    return instance.get(`/api/truck-rental/all-truck-tenant`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const createTenant = async (body) => {
  try {
    return instance.post(`/api/truck-rental/create-truck-tenant`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getTenantById = async (id_truck_tenant) => {
  try {
    return instance.get(`/api/truck-rental/truck-tenant/${id_truck_tenant}`);
  } catch (error) {
    console.log("error", error);
  }
};

const editTenant = async (id_truck_tenant, body) => {
  try {
    return instance.patch(
      `/api/truck-rental/edit-truck-tenant/${id_truck_tenant}`,
      body,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const createTruckTenantProject = async (body) => {
  try {
    return instance.post(`/api/truck-rental/create-truck-tenant-project`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const getAllTruckTenantProject = async (id_truck_tenant, param) => {
  try {
    return instance.get(
      `/api/truck-rental/all-truck-tenant-project/${id_truck_tenant}`,
      {
        params: param,
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const getTruckTenantProjectById = async (id_truck_tenant_project) => {
  try {
    return instance.get(
      `/api/truck-rental/truck-tenant-project/${id_truck_tenant_project}`
    );
  } catch (error) {
    console.log("error", error);
  }
};

const editTruckTenantProject = async (id_truck_tenant_project, body) => {
  try {
    return instance.patch(
      `/api/truck-rental/edit-truck-tenant-project/${id_truck_tenant_project}`,
      body
    );
  } catch (error) {
    console.log("error", error);
  }
};
const getAllLessor = async (param) => {
  try {
    return instance.get(`/api/truck-rental/all-truck-lessor`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};
const getLessorById = async (id_truck_lessor) => {
  try {
    return instance.get(`/api/truck-rental/truck-lessor/${id_truck_lessor}`);
  } catch (error) {
    console.log("error", error);
  }
};

const createLessor = async (body) => {
  try {
    return instance.post(`/api/truck-rental/create-truck-lessor`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const editLessor = async (id_truck_lessor, body) => {
  try {
    return instance.patch(
      `/api/truck-rental/edit-truck-lessor/${id_truck_lessor}`,
      body,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const getAllRentalOrder = async (param) => {
  try {
    return instance.get(`/api/truck-rental/all-rental-order`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const createRentalOrder = async (body) => {
  try {
    return instance.post(`/api/truck-rental/create-rental-order`, body);
  } catch (error) {
    console.log("error", error);
  }
};

const editOrderRental = async (id_rental, body) => {
  try {
    return instance.patch(
      `/api/truck-rental/edit-rental-order/${id_rental}`,
      body
    );
  } catch (error) {
    console.log("error", error);
  }
};

const cancelOrderRental = async (id_rental) => {
  try {
    return instance.patch(`/api/truck-rental/cancel-rental-order/${id_rental}`);
  } catch (error) {
    console.log("error", error);
  }
};
export {
  getAllTenant,
  createTenant,
  getTenantById,
  editTenant,
  createTruckTenantProject,
  getAllTruckTenantProject,
  getTruckTenantProjectById,
  editTruckTenantProject,
  getAllLessor,
  getLessorById,
  createLessor,
  editLessor,
  getAllRentalOrder,
  createRentalOrder,
  editOrderRental,
  cancelOrderRental,
};
